/* eslint-disable react/jsx-key */
import { Box, Button as ChakraButton, Card, CardBody, Flex, Image as ChakraImage, Text, Spacer, VStack, Center, HStack } from '@chakra-ui/react';
import * as React from 'react';

export type CallToActionProps = React.HTMLAttributes<HTMLElement> & {
  data: CallToActionDto;
  displayButton?: boolean;
};

export type CallToActionDto = {
  strategyLogo: string | null | undefined;
  strategyName: string | null | undefined;
  title: string;
  message: string;
  buttonText?: string;
};

export const CallToAction = ({ data, displayButton = false, ...props }: CallToActionProps) => {
  return (
    <Card
      minWidth={{ base: '80vw', lg: 'calc(100vw - 350px)' }}
      px={6}
      py={6}
      border="1px"
      borderColor="brand.warning.700"
      bgColor="brand.warning.100"
      borderRadius="8px"
    >
      <CardBody padding={0}>
        <Flex flexDirection={{ base: 'column', lg: 'row' }}>
          <Box>
            <VStack alignItems="stretch" spacing={0}>
              {data.strategyName && data.strategyLogo && (
                <HStack mb={2} padding={0}>
                  <ChakraImage src={data.strategyLogo} w="30px" h="30px"></ChakraImage>
                  <Text as="span" color="gray.700" textStyle={{ base: 'text-md-semibold', lg: 'text-xl-semibold' }}>
                    {data.strategyName}
                  </Text>
                </HStack>
              )}
              <Text color="black" textStyle={{ base: 'text-md-bold', lg: 'text-xl-bold' }}>
                {data.title}
              </Text>
              <Text color="gray.700" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }}>
                {data.message}
              </Text>
            </VStack>
          </Box>
          {displayButton && (
            <>
              <Spacer />

              <Center>
                <ChakraButton
                  mt={{ base: '1rem', lg: '0rem' }}
                  borderRadius="8px"
                  bgColor="blue.700"
                  textStyle="text-md-medium"
                  px="12px"
                  onClick={props.onClick}
                >
                  {data.buttonText}
                </ChakraButton>
              </Center>
            </>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
};

CallToAction.displayName = 'CallToAction';
