import { UIComponentSettingsType, UIComponentType } from '../interfaces';
import { Dictionary } from '../types';

export const UIComponentSettings: Dictionary<string, UIComponentSettingsType> = {
  [UIComponentType.billing]: {
    targetUrl: '/onboard/configureBilling',
    continuationText: 'to set-up billing for your account',
  },
  [UIComponentType.connect_coinbase]: {
    targetUrl: '/onboard/connectCoinbase',
    continuationText: 'to connect your Coinbase account',
  },
  [UIComponentType.kyc]: {
    targetUrl: '/onboard/verifyIdentity',
    continuationText: 'to verify your identity',
  },
  [UIComponentType.strategy]: {
    targetUrl: '/onboard/selectStrategy',
    continuationText: 'to select a strategy for investment',
  },
  [UIComponentType.home]: {
    targetUrl: '/home',
    continuationText: 'to continue to your dashboard',
  },
};
