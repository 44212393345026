import { Box, Button as ChakraButton, Card, CardBody, Flex, Image as ChakraImage, Text, Spacer, VStack, HStack } from '@chakra-ui/react';
import { faCircle, faCircleCheck, faCircleDot } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export type NextUpProps = React.HTMLAttributes<HTMLElement> & {
  data: NextUpDto;
  displaySteps?: boolean;
};

export type NextUpStepType = {
  name: string;
  isCurrent: boolean;
  isComplete: boolean;
};

export type NextUpDto = {
  strategyLogoUrl: string;
  strategyName: string;
  stepTitle: string;
  stepMessage: string;
  stepUrl: string;
  buttonText?: string;
  nextSteps: NextUpStepType[];
};

export const NextUp = ({ data, displaySteps = false, ...props }: NextUpProps) => {
  // Map next steps to list items
  const nextStepItems = data.nextSteps.map((value) => {
    return (
      <Flex key={value.name} justifyContent="start" mb={1} alignItems="center">
        <FontAwesomeIcon
          icon={value.isComplete ? faCircleCheck : value.isCurrent ? faCircleDot : faCircle}
          fontSize={value.isCurrent ? '16px' : '14px'}
          style={{ color: value.isComplete ? '#17B26A' : 'black' }} // TODO: figure out how to use branding colors: green.700
        />
        <Text color="black" textStyle={value.isCurrent ? 'text-sm-bold' : 'text-sm-regular'} ms={2}>
          {value.name}
        </Text>
      </Flex>
    );
  });

  return (
    <Card minWidth="calc(100vw - 350px)" px={6} py={6} border="1px" borderColor="gray.300" borderRadius="8px">
      <CardBody padding={0}>
        <VStack alignItems="stretch" spacing={0}>
          <Text color="gray.500" textStyle="text-md-semibold" padding={0}>
            Next up
          </Text>
          <Flex pt={2}>
            <HStack>
              <VStack alignItems="stretch" spacing={0} me={1}>
                <HStack mb={2} padding={0}>
                  <ChakraImage src={data.strategyLogoUrl} w="30px" h="30px"></ChakraImage>
                  <Text as="span" color="gray.700" textStyle="text-xl-semibold">
                    {data.strategyName}
                  </Text>
                </HStack>
                <Text color="black" textStyle="text-xl-bold" mb={0}>
                  {data.stepTitle}
                </Text>
                <Text color="gray.500" textStyle="text-sm-regular">
                  {data.stepMessage}
                </Text>
              </VStack>

              <Spacer />

              {data.buttonText && (
                <Box h="100%" alignContent="flex-end" mb={4} ms={4}>
                  <ChakraButton borderRadius="8px" bgColor="blue.700" textStyle="text-md-medium" mt={4} onClick={props.onClick}>
                    {data.buttonText}
                  </ChakraButton>
                </Box>
              )}
            </HStack>

            <Spacer />

            {displaySteps && data.nextSteps !== null && data.nextSteps !== undefined && data.nextSteps.length > 0 && (
              <Box borderLeft="1px" borderLeftColor="brand.border" paddingLeft="26px">
                {nextStepItems}
              </Box>
            )}
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
};

NextUp.displayName = 'NextUp';
