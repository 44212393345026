import { create } from 'zustand';

import { OnboardingEventDto, StrategyDto } from '../../../lib/types';

export type Onboard = {
  strategy: StrategyDto | null;
  onboardEvents: OnboardingEventDto[] | null;
  userStrategyId: string | null;
};

type OnboardStore = {
  onboard: Onboard;
  setOnboard: (state: Onboard) => void;
  clearOnboard: () => void;
};

const defaultState: Onboard = {
  strategy: null,
  onboardEvents: null,
  userStrategyId: null,
};

export const useOnboardStore = create<OnboardStore>((set) => ({
  onboard: defaultState,
  setOnboard: (onboard: Onboard) => {
    set({ onboard });
    console.log('state in store hook: ', onboard);
  },
  clearOnboard: () => set({ onboard: defaultState }),
}));

// Determine URL for provided step
export const getUrlForOnboardEvent = async (stepIndex: number | null, onboard: Onboard): Promise<string> => {
  let targetUrl = '/home';

  if (onboard.onboardEvents === null || onboard.onboardEvents.length === 0) return targetUrl;

  if (stepIndex === null) {
    const incompleteEvents = onboard.onboardEvents.filter((e) => e.isComplete === false);
    if (incompleteEvents === null) return targetUrl;
    stepIndex = onboard.onboardEvents.indexOf(incompleteEvents[0]);
  } else {
    stepIndex--;
  }

  if (stepIndex > -1 && stepIndex < onboard.onboardEvents.length) {
    const uiComponent = onboard.onboardEvents[stepIndex].uiComponent?.toLowerCase() || '';
    switch (uiComponent) {
      case 'strategy selection':
        targetUrl = '/onboard/selectStrategy';
        break;
      case 'kyc':
        targetUrl = '/onboard/verifyIdentity';
        break;
      case 'connect coinbase':
        targetUrl = '/onboard/connectCoinbase';
        break;
      case 'billing':
        targetUrl = '/onboard/configureBilling';
        break;
    }
  }

  return targetUrl;
};

export const getUrlForNextOnboardEvent = async (onboard: Onboard): Promise<string> => {
  return getUrlForOnboardEvent(null, onboard);
};
