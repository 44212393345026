import { useMutation } from '@tanstack/react-query';

import { axios as AuthAxios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';

export type AcceptedAgreementDto = {
  agreementId: string;
};

export type PlatformAgreementDto = {
  agreementId: string;
  agreementName: string | null;
  agreementUrl: string | null;
};

// TODO: Once CORs resolved, migrate document retrieval logic here
// export const downloadPlatformAgreement = async (url: string): Promise<string></string> =>  {
//   constant response = await;
// };

export const getPlatformAgreement = async (): Promise<IBaseResponse<PlatformAgreementDto>> => {
  return AuthAxios.get('/agreement/platform-agreement');
};

export const acceptAgreement = async (data: AcceptedAgreementDto): Promise<IBaseResponse<boolean>> => {
  return await AuthAxios.post('/accepted-agreement', data);
};

export const useAcceptAgreement = () => {
  return useMutation({
    mutationFn: acceptAgreement,
  });
};
