import { OnboardingEventDto } from './onboardingEventDto';

export type MonetaryAmount = {
  value: number;
  currency: string | null;
};

export type SpotPosition = {
  assetName: string | null; // e.g., Bitcon
  asset: string | null; // e.g., BTC
  assetImgUrl: string | null;
  totalBalanceFiat: number;
  totalBalanceCrypto: number;
  availableToTradeFiat: number;
  availableToTradeCrypto: number;
  allocation: number;
  oneDayChange: number;
  costBasis: MonetaryAmount;
  isCash: boolean;
};

export type ExchangeAccountBalance = {
  exchangeAccountUUID: string | null;
  totalBalance: MonetaryAmount | null;
  totalCashEquivalentBalance: MonetaryAmount | null;
  totalCryptoBalance: MonetaryAmount | null;
  spotPositions: SpotPosition[] | null;
};

export type UserStrategyDashboardDto = {
  id: string;
  strategyId: string;
  strategyName: string | null;
  strategyDescription: string | null;
  completedOnboardingDate: Date | null;
  isBillingActive: boolean;
  firstFundedDate: Date | null;
  exchangeAccountBalance: ExchangeAccountBalance;
  userStrategyOnboardingStatus: {
    onboardingEvents: OnboardingEventDto[];
  };
};

export type UserDashboardDto = {
  userStrategies: UserStrategyDashboardDto[] | null;
};

export const EmptyStrategyDashboardDto: UserStrategyDashboardDto = {
  id: '',
  strategyId: '',
  strategyName: '',
  strategyDescription: '',
  completedOnboardingDate: null,
  isBillingActive: false,
  firstFundedDate: null,
  exchangeAccountBalance: {
    exchangeAccountUUID: '',
    totalBalance: null,
    totalCashEquivalentBalance: null,
    totalCryptoBalance: null,
    spotPositions: null,
  },
  userStrategyOnboardingStatus: {
    onboardingEvents: [],
  },
};

export const EmptyDashboardData: UserDashboardDto = {
  userStrategies: [EmptyStrategyDashboardDto],
};
