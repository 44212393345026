import { Flex, HStack, Image as ChakraImage, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Text, VStack, Spacer } from '@chakra-ui/react';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import iconTrendingDown from '../../../../assets/icon-trending-down.svg';
import iconTrendingUp from '../../../../assets/icon-trending-up.svg';
import { SpotPosition, UserStrategyDashboardDto } from '../../../lib/types';

interface IPortfolioInput {
  userStrategy: UserStrategyDashboardDto;
}

export const Portfolio = ({ userStrategy }: IPortfolioInput) => {
  //--
  // format positions -> maybe a widget?
  function formatPositions(positions: SpotPosition[]) {
    // Apply the style to a div that renders your text
    return positions.map((position) => {
      return (
        <Tr key={position.asset}>
          <Td color="black" textStyle="text-sm-regular" ps={0}>
            <HStack padding={0} gap={1} alignContent="start">
              <ChakraImage src={position.assetImgUrl || ''} h="32px" w="32px" />
              <Text color="black" textStyle="text-sm-regular" ms={2}>
                {position.assetName}
              </Text>
              <Text color="gray.300" textStyle="text-sm-regular" ms={0}>
                {position.asset}
              </Text>
            </HStack>
          </Td>
          <Td color="black" textStyle="text-sm-regular">
            {position.totalBalanceFiat === 0.0 ? '$0.00' : position.totalBalanceFiat.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Td>
          <Td color="black" textStyle="text-sm-regular">
            {position.totalBalanceCrypto === 0.0
              ? '----'
              : position.totalBalanceCrypto.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 })}
          </Td>
          <Td textStyle="text-sm-regular">
            <HStack padding={0} gap={1} alignContent="start">
              {position.oneDayChange !== 0 && <ChakraImage src={position.oneDayChange < 0 ? iconTrendingDown : iconTrendingUp} h="24px" w="24px" />}
              <Text color={position.oneDayChange < 0 ? 'red.700' : 'green.700'} textStyle="text-sm-regular">
                {position.oneDayChange === 0 ? '----' : position.oneDayChange.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </Text>
            </HStack>
          </Td>
          <Td color="black" textStyle="text-sm-regular">
            {position.allocation === 0.0
              ? '----'
              : position.allocation.toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 })}
          </Td>
          <Td color="black" textStyle="text-sm-regular" pe={0}>
            {position.costBasis.value.toLocaleString('en-US', {
              style: 'currency',
              currency: position.costBasis.currency ?? 'USD',
            }) || '----'}
          </Td>
        </Tr>
      );
    });
  }

  return (
    <VStack minWidth="calc(100vw - 350px)" px={6} py={6} border="1px" borderColor="gray.300" borderRadius="8px">
      <Flex w="100%" direction="row" gap={4}>
        <Text w="20%" textStyle="text-lg-bold">
          {userStrategy.strategyName}
        </Text>
        <Flex w="80%" alignItems="stretch" py={5} px={6} borderRadius="8px" borderColor="blue.50" bgColor="blue.50" justifyContent="start">
          <VStack alignItems="start">
            <Text color="gray.500" textStyle="text-xs-medium">
              Total Balance
            </Text>
            <Text color="black" textStyle="text-sm-medium" ps={0} ms={0}>
              {userStrategy.exchangeAccountBalance.totalBalance?.value.toLocaleString('en-US', {
                style: 'currency',
                currency: userStrategy.exchangeAccountBalance.totalBalance?.currency ?? 'USD',
              }) || '----'}
            </Text>
          </VStack>
          <Spacer />
          <VStack alignItems="start">
            <Text color="gray.500" textStyle="text-xs-medium">
              Total Cash Equivalent Balance
            </Text>
            <Text color="black" textStyle="text-sm-medium" ps={0} ms={0}>
              {userStrategy.exchangeAccountBalance.totalCashEquivalentBalance?.value.toLocaleString('en-US', {
                style: 'currency',
                currency: userStrategy.exchangeAccountBalance.totalCashEquivalentBalance?.currency ?? 'USD',
              }) || '----'}
            </Text>
          </VStack>
          <Spacer />
          <VStack alignItems="start">
            <Text color="gray.500" textStyle="text-xs-medium">
              Total Crpto Balance
            </Text>
            <Text color="black" textStyle="text-sm-medium" ps={0} ms={0}>
              {userStrategy.exchangeAccountBalance.totalCryptoBalance?.value.toLocaleString('en-US', {
                style: 'currency',
                currency: userStrategy.exchangeAccountBalance.totalCryptoBalance?.currency ?? 'USD',
              }) || '----'}
            </Text>
          </VStack>
        </Flex>
        <FontAwesomeIcon icon={faCog} fontSize="20px" color="#D0D5DD" /> {/* TODO Figure out how to pass Chakra color */}
      </Flex>

      <TableContainer w="100%" maxH="400px">
        <Table variant="simple">
          <Thead textTransform="none">
            <Tr>
              <Th textTransform="none" textStyle="text-sm-bold" color="gray.500" ps={0}>
                Asset
              </Th>
              <Th textTransform="none" textStyle="text-sm-bold" color="gray.500">
                Total Balance
              </Th>
              <Th textTransform="none" textStyle="text-sm-bold" color="gray.500">
                Total Crypto Balance
              </Th>
              <Th textTransform="none" textStyle="text-sm-bold" color="gray.500">
                One Day Change
              </Th>
              <Th textTransform="none" textStyle="text-sm-bold" color="gray.500">
                Allocation
              </Th>
              <Th textTransform="none" textStyle="text-sm-bold" color="gray.500" pe={0}>
                Cost Basis
              </Th>
            </Tr>
          </Thead>
          <Tbody>{userStrategy.exchangeAccountBalance.spotPositions && formatPositions(userStrategy.exchangeAccountBalance.spotPositions)}</Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
};
