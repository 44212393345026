import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AnonymousLayout } from '../../../components/Layout';

import { ConfirmEmail } from './ConfirmEmail';
import { ConfirmPasswordChanged } from './ConfirmPasswordChanged';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { Register } from './Register';
import { ResetPassword } from './ResetPassword';
import { VerifyEmail } from './VerifyEmail';

export const AuthRoutes = () => {
  return (
    <AnonymousLayout>
      <Routes>
        <Route index element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="confirmEmail" element={<ConfirmEmail />} />
        <Route path="verifyEmail" element={<VerifyEmail />} />
        <Route path="login" element={<Login />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="confirmPasswordChanged" element={<ConfirmPasswordChanged />} />
        <Route path="resetPassword" element={<ResetPassword />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </AnonymousLayout>
  );
};
