import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Link as ChakraLink,
  List as ChakraList,
  ListIcon as ChakraListIcon,
  ListItem as ChakraListItem,
  HStack,
  Text,
  Spacer,
  Center,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';
import Turnstile from 'react-turnstile';

import { environment } from '../../../../environments/environment';
import { ButtonIcon, WindowAlert } from '../../../components';
import { IBaseResponse } from '../../../lib/interfaces';
import { useRegister } from '../../../lib/providers/auth';
import { EmailRegex, MaxEmailLength } from '../validation/EmailRules';
import { DigitRegex, LowercaseRegEx, MinLengthRegEx, SpecialCharactersRegEx, UppercaseRegex, ValidPasswordRegex } from '../validation/PasswordRules';

const CheckListIcon = (props: any) => {
  return <CheckCircleIcon {...props} />;
};

interface IFormInput {
  email: string;
  password: string;
  confirmPassword: string;
  captchaToken?: string;
  hasDigit: boolean;
  hasMinLength: boolean;
  hasLowercase: boolean;
  hasSpecialChar: boolean;
  hasUppercase: boolean;
}

interface IRegisterFormProps {
  onSuccess: (email: string) => any;
}

export const RegisterForm = ({ onSuccess }: IRegisterFormProps) => {
  const registerFn = useRegister();

  const [registerError, setRegisterError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [cftToken, setCftToken] = useState('');
  const requireCaptcha = !(environment.TargetEnvironment === 'local');

  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<IFormInput>();

  const watchName = watch('password', '');

  React.useEffect(() => {
    setValue('hasDigit', DigitRegex.test(watchName));
    setValue('hasMinLength', MinLengthRegEx.test(watchName));
    setValue('hasLowercase', LowercaseRegEx.test(watchName));
    setValue('hasSpecialChar', SpecialCharactersRegEx.test(watchName));
    setValue('hasUppercase', UppercaseRegex.test(watchName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchName]);

  const onSubmit = async (values: IFormInput) => {
    // Here you would typically send the token to your backend for verification
    console.log('Submitting form with token:', cftToken);
    values.captchaToken = cftToken;

    registerFn.mutate(values, {
      onSuccess: () => {
        onSuccess(values.email);
      },
      onError: (error) => {
        reset();
        setErrorMessage(
          (error as AxiosError<IBaseResponse<boolean>>)?.response?.data?.errorMessages?.toString() ||
            `Registration failed. Please contact ${environment.SupportName}.`
        );
        setRegisterError(true);
      },
    });

    // Reset token after submission
    setCftToken('');
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to create an account for you"
        alertMessage={errorMessage}
        alertStatus="error"
        isOpen={registerError}
        onClose={() => setRegisterError(false)}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.email} mb={5}>
          <Input
            id="email"
            color="brand.gray.400"
            placeholder="Email"
            ps="2"
            textStyle="text-md-regular"
            type="text"
            variant="flushed"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: EmailRegex,
                message: 'Invalid email address',
              },
              maxLength: {
                value: MaxEmailLength,
                message: `Email exceeds maximum length of ${MaxEmailLength} characters`,
              },
            })}
          />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.password} mb={5}>
          <Input
            id="password"
            color="brand.gray.400"
            placeholder="Password"
            ps="2"
            textStyle="text-md-regular"
            type="password"
            variant="flushed"
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
              pattern: {
                value: ValidPasswordRegex,
                message:
                  'Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space, and it must be 8 characters long',
              },
            })}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.confirmPassword} mb={5}>
          <Input
            id="confirmPassword"
            color="brand.gray.400"
            placeholder="Confirm Password"
            ps="2"
            textStyle="text-md-regular"
            type="password"
            variant="flushed"
            {...register('confirmPassword', {
              required: 'Please confirm your password',
              validate: (value) => value === watch('password') || 'Passwords do not match',
            })}
          />
          <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
        </FormControl>

        <ChakraList spacing={3} textStyle="text-xs-regular" color="brand.gray.500" mb={5}>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasMinLength') ? 'brand.success.300' : 'brand.gray.300'} />
            Must be at least 8 characters
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasUppercase') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one uppercase character
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasLowercase') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one lowercase character
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasDigit') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one digit
          </ChakraListItem>
          <ChakraListItem>
            <ChakraListIcon as={CheckListIcon} w={4} h={4} color={watch('hasSpecialChar') ? 'brand.success.300' : 'brand.gray.300'} />
            Must contain at least one special character
          </ChakraListItem>
        </ChakraList>

        {requireCaptcha && (
          <Flex mt={8}>
            <Turnstile sitekey={`${environment.CftSiteKey}`} onVerify={(token) => setCftToken(token)} />
          </Flex>
        )}

        <Flex mt={8} flexDirection={{ base: 'column', lg: 'row' }}>
          <Button
            bgColor="brand.blue.700"
            color="white"
            h="48px"
            pe="2px"
            mb={{ base: '2rem', lg: '0rem' }}
            rightIcon={<ButtonIcon />}
            isLoading={isSubmitting}
            isDisabled={isSubmitting || isSubmitSuccessful || (requireCaptcha && !cftToken)}
            textStyle="text-md-regular"
            type="submit"
          >
            Get Started
          </Button>

          <Spacer />

          <Center>
            <HStack>
              <Text color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-lg-regular' }}>
                Already have an account?{' '}
              </Text>
              <ChakraLink
                as={ReactRouterLink}
                color="brand.blue.700"
                textStyle={{ base: 'text-sm-regular', lg: 'text-lg-regular' }}
                to={'/auth/login'}
              >
                Log in
              </ChakraLink>
            </HStack>
          </Center>
        </Flex>
      </form>
    </>
  );
};
