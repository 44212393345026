import {
  Box,
  Button as ChakraButton,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Image as ChakraImage,
  Link as ChakraLink,
  ListItem,
  OrderedList,
  Text,
  VStack,
  Input,
  Spacer,
} from '@chakra-ui/react';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import coinbaseAdvancedLogo from '../../../../../assets/icon-CoinbaseAdvanced.svg';
import iconShieldCheck from '../../../../../assets/icon-shield-check.svg';
import contentImage from '../../../../../assets/screenshot-ConnectCoinbase.png';
import { environment } from '../../../../../environments/environment';
import { BrandSpinner, CopyToClipboardButton, WindowAlert } from '../../../../components';
import { createUserExchange, getUserExchangeIpWhitelist } from '../../../../lib/apis';
import { IBaseResponse } from '../../../../lib/interfaces';
import { ApiKeyNameMaxLength, PortfolioNameMaxLength, PrivateKeyMaxLength, UserExchangeDto, UserExchangeRequest } from '../../../../lib/types';

export interface IConnectCoinbaseFormProps {
  userStrategyId: string;
  onSuccess: (success: boolean) => any;
}

interface IFormInput {
  portfolioName: string;
  apiKeyName: string;
  privateKey: string;
}

export const ConnectCoinbaseForm = ({ userStrategyId, onSuccess }: IConnectCoinbaseFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IFormInput>();

  const defaultLoadErrorMessage = `We could not download the IP Address(es) to whitelist. Please contact ${environment.SupportName}`;
  const defaultSaveErrorMessage = `Failed to connect to Coinbase. Please contact  ${environment.SupportName}.`;

  // handling page state
  const [connectError, setConnectError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Form content
  const [whitelistIPs, setWhitelistIPs] = useState('');

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadExchangeSettings() {
      try {
        const response = await getUserExchangeIpWhitelist();
        if (response.successful === true && response.payload !== null && response.payload.length > 0) {
          setWhitelistIPs(response.payload);
        } else {
          setErrorMessage(defaultLoadErrorMessage);
          setConnectError(true);
        }
      } catch (error) {
        setErrorMessage((error as AxiosError<IBaseResponse<string>>)?.response?.data?.errorMessages?.toString() || defaultLoadErrorMessage);
        setConnectError(true);
      }

      setIsLoading(false);
    }

    loadExchangeSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Coinbase Set-up Complete
  const onSubmit = async (values: IFormInput) => {
    try {
      const re = /\\n/g;
      const request: UserExchangeRequest = {
        portfolioName: values.portfolioName,
        apiKeyName: values.apiKeyName,
        privateKey: values.privateKey.replace(re, '\n'),
        userStrategyId: userStrategyId,
      };
      const response = await createUserExchange(request);

      if (response.successful) {
        onSuccess(true);
        return;
      }

      setErrorMessage(response.errorMessages.length < 1 ? defaultSaveErrorMessage : response.errorMessages.toString());
      setConnectError(true);
      //
    } catch (error) {
      const errMsgs = (error as AxiosError<IBaseResponse<UserExchangeDto>>)?.response?.data?.errorMessages || [{}];
      const isStringArray =
        Array.isArray(errMsgs) &&
        errMsgs.length > 0 &&
        errMsgs.every((value) => {
          return typeof value === 'string';
        });
      setErrorMessage(isStringArray ? errMsgs.toString() : defaultSaveErrorMessage);
      setConnectError(true);
    }
  };

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w="600px">
          <Text color="gray.400" textStyle="text-md-regular" mb={8}>
            We are downloading content. Please wait...
          </Text>

          <BrandSpinner />
        </VStack>
      </Center>
    );

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertStatus="error"
        isOpen={connectError}
        onClose={() => setConnectError(false)}
      />

      <Flex gap="112px">
        <Box w="403px" flexShrink={0}>
          <Text textStyle="display-xs-semibold" color="black">
            Connect exchange Coinbase
          </Text>

          <HStack mb={4}>
            <Text as="span" textStyle="display-xs-semibold" color="black">
              Advanced
            </Text>
            <VStack alignItems="end" gap={0}>
              <ChakraImage src={coinbaseAdvancedLogo} h="27.4px" w="92px" ms={3} />
              <Text
                as="span"
                color="#5B5B5B"
                fontSize="8.8px"
                fontWeight={500}
                fontStyle="normal"
                fontFamily={"'Plus Jakarta Sans', sans-serif"}
                lineHeight="normal"
                me="0.1rem"
                mt="-0.25rem"
              >
                Advanced
              </Text>
            </VStack>
          </HStack>

          <Box h="275px" flexShrink={0} border="1px" borderColor="green.50" borderRadius={8} background="green.50" py={5} px={4}>
            <HStack padding={0} mb={4}>
              <ChakraImage src={iconShieldCheck} w="24px" h="24px" />
              <Text textStyle="text-sm-medium" color="black" me={1}>
                Connect Keys Securely
              </Text>
              <ChakraLink
                color="blue.700"
                onClick={() => window.open(environment.SecurityBestPracticesUrl, '_blank', 'rel=noopener noreferrer')}
                textDecoration="none"
              >
                <Text as="span" textStyle="text-sm-medium" textDecoration="underline" color="blue.700" me={2}>
                  Full Guide
                </Text>
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="12px" color="blue.700" />
              </ChakraLink>
            </HStack>
            <OrderedList mt={6}>
              <ListItem mb={3}>
                <Text textStyle="text-sm-medium" color="black">
                  Login to your exchange account and go to
                </Text>
                <ChakraLink
                  color="blue.700"
                  onClick={() => window.open(environment.CoinbaseAdvancedApiSettingsUrl, '_blank', 'rel=noopener noreferrer')}
                  textDecoration="none"
                >
                  <Text as="span" textStyle="text-sm-medium" textDecoration="underline" color="blue.700" me={2}>
                    Api Settings
                  </Text>
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize="12px" color="blue.700" />
                </ChakraLink>
              </ListItem>
              <ListItem mb={3}>
                <Text mb={1} textStyle="text-sm-medium" color="black">
                  Turn on IP Whitelisting and copy/paste the following list of IP addresses:
                </Text>
                <HStack w="100%">
                  <Text
                    as="span"
                    px={1}
                    py={2}
                    background="white"
                    color="black"
                    borderRadius={1}
                    textStyle="text-sm-medium"
                    w="275px"
                    h="33px"
                    noOfLines={1}
                  >
                    {whitelistIPs}
                  </Text>
                  <Spacer />
                  <CopyToClipboardButton content={whitelistIPs} />
                </HStack>
              </ListItem>
              <ListItem>
                <Text textStyle="text-sm-medium" color="black">
                  Paste generated data below:
                </Text>
              </ListItem>
            </OrderedList>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Text mt={6} textStyle="text-md-semibold">
              Portfolio Name:
            </Text>
            <FormControl isInvalid={!!errors.portfolioName} mb={1}>
              <Input
                id="portfolioName"
                color="brand.gray.400"
                placeholder="Enter Coinbase newly created porfolio name"
                ps="1"
                textStyle="text-md-regular"
                type="text"
                variant="flushed"
                {...register('portfolioName', {
                  required: 'Portfolio Name is required',
                  maxLength: {
                    value: PortfolioNameMaxLength,
                    message: `Portfolio Name exceeds maximum length of ${PortfolioNameMaxLength} characters`,
                  },
                })}
              />
              <FormErrorMessage>{errors.portfolioName && errors.portfolioName.message}</FormErrorMessage>
            </FormControl>

            <Text mt={4} textStyle="text-md-semibold">
              API Key Name:
            </Text>
            <FormControl isInvalid={!!errors.apiKeyName} mb={1}>
              <Input
                id="apiKeyName"
                color="brand.gray.400"
                placeholder="Enter API Key Name"
                ps="1"
                textStyle="text-md-regular"
                type="text"
                variant="flushed"
                {...register('apiKeyName', {
                  required: 'API Key Name is required',
                  maxLength: {
                    value: ApiKeyNameMaxLength,
                    message: `API Key Name exceeds maximum length of ${ApiKeyNameMaxLength} characters`,
                  },
                })}
              />
              <FormErrorMessage>{errors.apiKeyName && errors.apiKeyName.message}</FormErrorMessage>
            </FormControl>

            <Text mt={4} textStyle="text-md-semibold">
              Private Key:
            </Text>
            <FormControl isInvalid={!!errors.privateKey} mb={1}>
              <Input
                id="privateKey"
                color="brand.gray.400"
                placeholder="Enter Private Key"
                ps="1"
                textStyle="text-md-regular"
                type="text"
                variant="flushed"
                {...register('privateKey', {
                  required: 'Private Key is required',
                  maxLength: {
                    value: PrivateKeyMaxLength,
                    message: `Private Key exceeds maximum length of ${PrivateKeyMaxLength} characters`,
                  },
                })}
              />
              <FormErrorMessage>{errors.privateKey && errors.privateKey.message}</FormErrorMessage>
            </FormControl>

            <Center w="100%" mt={5}>
              <ChakraButton
                w="100%"
                bgColor="brand.blue.700"
                borderRadius={8}
                color="white"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                textStyle="text-md-medium"
                type="submit"
              >
                Connect Coinbase Advanced
              </ChakraButton>
            </Center>
          </form>

          <VStack w="100%" mt={10}>
            <Text textStyle="text-sm-regular">Don’t have an account?</Text>
            <ChakraLink
              color="blue.700"
              onClick={() => window.open(environment.CoinbaseAdvancedSignUpUrl, '_blank', 'rel=noopener noreferrer')}
              textDecoration="none"
            >
              <Text textStyle="text-sm-regular" textDecoration="underline" color="blue.700">
                Create a new Coinbase Advanced account
              </Text>
            </ChakraLink>
          </VStack>
        </Box>

        <Box w="585px">
          <VStack w="100%" alignItems="start">
            <Text textStyle="display-xs-semibold" color="black">
              Please see instructions in the video below:
            </Text>
          </VStack>
          <ChakraImage src={contentImage} w="585px" h="350px" mt="44px"></ChakraImage>
        </Box>
      </Flex>
    </>
  );
};
