import { Button, Flex, FormControl, FormErrorMessage, Input, Link as ChakraLink, HStack, Text, Spacer, Center } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';

import { ButtonIcon, WindowAlert } from '../../../components';
import { useLogin } from '../../../lib/providers/auth';
import { UserInfoDto } from '../../../lib/types';
import { EmailRegex } from '../validation/EmailRules';

interface IFormInput {
  email: string;
  password: string;
}

interface ILoginFormProps {
  onSuccess: (params: UserInfoDto | null) => any;
}

export const LoginForm = ({ onSuccess }: ILoginFormProps) => {
  const login = useLogin();

  const [loginError, setLoginError] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IFormInput>();

  const onSubmit = async (values: IFormInput) => {
    login.mutate(values, {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: () => {
        setLoginError(true);
      },
    });
  };

  return (
    <>
      <WindowAlert
        alertTitle="That login information does not match an existing account"
        alertMessage="Please check your credentials then try again. Or, click “Forgot Password?” to reset it. After 10 tries, your account will be locked for 10 minutes."
        alertStatus="error"
        isOpen={loginError}
        onClose={() => setLoginError(false)}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.email} mb={10}>
          <Input
            id="email"
            color="brand.gray.400"
            placeholder="Email"
            ps="2"
            textStyle="text-md-regular"
            type="text"
            variant="flushed"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: EmailRegex,
                message: 'Invalid email address',
              },
            })}
          />
          <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.password} mb={{ base: '1.5rem', lg: '4rem' }}>
          <Input
            id="password"
            color="brand.gray.400"
            placeholder="Password"
            ps="2"
            textStyle="text-md-regular"
            type="password"
            variant="flushed"
            {...register('password', {
              required: 'Password is required',
            })}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
        </FormControl>

        <Flex>
          <Spacer />

          <ChakraLink as={ReactRouterLink} color="brand.blue.700" textStyle="text-sm-semibold" to={'/auth/forgotPassword'}>
            Forgot Password?
          </ChakraLink>
        </Flex>

        <Flex mt={8} flexDirection={{ base: 'column', lg: 'row' }}>
          <Button
            bgColor="brand.blue.700"
            color="white"
            h="48px"
            pe="2px"
            mb={{ base: '2rem', lg: '0rem' }}
            rightIcon={<ButtonIcon />}
            isLoading={isSubmitting}
            loadingText="Logging In"
            textStyle={{ base: 'text-md-regular', lg: 'text-md-medium' }}
            type="submit"
          >
            Log In
          </Button>

          <Spacer />

          <Center>
            <HStack>
              <Text color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-lg-regular' }}>
                Don&apos;t have an account?{' '}
              </Text>
              <ChakraLink
                as={ReactRouterLink}
                color="brand.blue.700"
                textStyle={{ base: 'text-sm-regular', lg: 'text-lg-regular' }}
                to={'/auth/register'}
              >
                Sign up
              </ChakraLink>
            </HStack>
          </Center>
        </Flex>
      </form>
    </>
  );
};
