import { Box, Center, Flex, Heading, Image, Link as ChakraLink, Show as ChakraShow, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';
import { RegisterForm } from '../components/RegisterForm';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src={dynamicInvestSvg} w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box w="500px">
        <Heading textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
          Let&apos;s get Started
        </Heading>

        <Text color="brand.gray.500" my={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          You&apos;ll be able to explore Dynamic after you create an account.
        </Text>

        <RegisterForm onSuccess={() => navigate('/auth/confirmEmail')} />

        <Box color="brand.gray.400" mt="36px" textStyle="text-sm-regular">
          By providing your information and clicking Get Started, you agree to our
          <ChakraLink as={ReactRouterLink} textDecoration="underline" mx="4px" to={''}>
            Terms of Use
          </ChakraLink>
          and
          <ChakraLink as={ReactRouterLink} textDecoration="underline" ms="4px" to={''}>
            Privacy Policy
          </ChakraLink>
          . You also elect to receive updates, newsletters and offers from Dynamic.
        </Box>
      </Box>
    </Flex>
  );
};
