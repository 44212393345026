/* eslint-disable quotes */
import { ChakraProvider, defineStyleConfig, extendTheme, Flex } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ScrollToTop, Spinner } from '../components';
import { queryClient } from '../lib/utils/react-query';

import '@fontsource/manrope/400.css';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/600.css';
import '@fontsource/manrope/700.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: '0.2px',
  },
  sizes: {
    md: {
      fontSize: 'md',
      px: 6,
      py: 3,
    },
  },
  variants: {
    outline: {
      border: '1px solid',
      borderColor: 'brand.black',
      color: 'brand.black',
    },
    solid: {
      color: 'white',
      bg: 'brand.black',
    },
    rounded: {
      borderRadius: '40px',
      bg: 'brand.black',
      color: 'white',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'rounded',
  },
});

const Input = defineStyleConfig({
  sizes: {
    lg: {
      field: {
        fontSize: 'md',
        height: '3.5rem',
        _placeholder: {
          color: '#AAA9A8',
        },
      },
    },
  },
  variants: {
    flushed: {
      field: {
        _placeholder: {
          color: '#98A2B3',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'flushed',
  },
});

const NumberInput = defineStyleConfig({
  sizes: {
    lg: {
      field: {
        fontSize: 'md',
        height: '3.5rem',
        _placeholder: {
          color: '#98A2B3',
        },
      },
    },
  },
  variants: {
    flushed: {
      field: {
        _placeholder: {
          color: '#98A2B3',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'flushed',
  },
});

// https://v2.chakra-ui.com/docs/styled-system/text-and-layer-styles
const theme = extendTheme({
  fonts: {
    heading: `'Manrope', sans-serif`,
    body: `'Manrope', sans-serif`,
  },
  colors: {
    placeholder: '#AAA9A8',
    blue: {
      100: '#E6EDFE',
      200: '#CEDBFC',
      300: '#B5C9FB',
      400: '#9DB7F9',
      500: '#6B92F7',
      600: '#3A6EF4',
      700: '#094AF1',
    },
    gray: {
      25: '#FCFCFD',
      50: '#F9FAFB',
      100: '#F2F4F7',
      200: '#EAECF0',
      300: '#D0D5DD',
      400: '#98A2B3',
      500: '#667085',
      600: '#475467',
      700: '#344054',
      800: '#182230',
      900: '#0C111D',
    },
    green: {
      50: '#E8F7F0',
      100: '#DDF6EB',
      200: '#C7EEDC',
      300: '#B1E7CE',
      400: '#9BDFC0',
      500: '#6FD0A3',
      600: '#43C187',
      700: '#17B26A',
    },
    red: {
      100: '#FFEFEF',
      200: '#FFDEDE',
      300: '#FFCECE',
      400: '#FFBDBD',
      500: '#FF9C9C',
      600: '#FF7B7B',
      700: '#FF5A5A',
    },
    yellow: {
      100: '#FFF9E6',
      200: '#FFF4CC',
      300: '#FFEEB3',
      400: '#FFE999',
      500: '#FFDD66',
      600: '#FFD233',
      700: '#FFC700',
    },
    brand: {
      black: '#000000',
      white: '#FFFFFF',
      grayLight: '#98A2B3',
      border: '#C0BFBD',
      borderLight: '#E9E8E6',
      scrollbarBg: '#E0E0E0',
      500: '#3A6EF4',
      blue: {
        50: '#F4F7FE',
        100: '#E6EDFE',
        200: '#CEDBFC',
        300: '#B5C9FB',
        400: '#9DB7F9',
        500: '#6B92F7',
        600: '#3A6EF4',
        700: '#094AF1',
      },
      gray: {
        25: '#FCFCFD',
        50: '#F9FAFB',
        100: '#F2F4F7',
        200: '#EAECF0',
        300: '#D0D5DD',
        400: '#98A2B3',
        500: '#667085',
        600: '#475467',
        700: '#344054',
        800: '#182230',
        900: '#0C111D',
      },
      error: {
        100: '#FFEFEF',
        200: '#FFDEDE',
        300: '#FFCECE',
        400: '#FFBDBD',
        500: '#FF9C9C',
        600: '#FF7B7B',
        700: '#FF5A5A',
      },
      success: {
        50: '#E8F7F0',
        100: '#DDF6EB',
        200: '#C7EEDC',
        300: '#B1E7CE',
        400: '#9BDFC0',
        500: '#6FD0A3',
        600: '#43C187',
        700: '#17B26A',
      },
      warning: {
        100: '#FFF9E6',
        200: '#FFF4CC',
        300: '#FFEEB3',
        400: '#FFE999',
        500: '#FFDD66',
        600: '#FFD233',
        700: '#FFC700',
      },
    },
  },
  components: {
    Button,
    Input,
    NumberInput,
  },
  textStyles: {
    'display-2xl-regular': {
      fontSize: '72px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '-0.02em',
      lineHeight: '90px',
    },
    'display-2xl-medium': {
      fontSize: '72px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '-0.02em',
      lineHeight: '90px',
    },
    'display-2xl-semibold': {
      fontSize: '72px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: '90px',
    },
    'display-2xl-bold': {
      fontSize: '72px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '90px',
    },
    'display-xl-regular': {
      fontSize: '60px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '-0.02em',
      lineHeight: '72px',
    },
    'display-xl-medium': {
      fontSize: '60px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '-0.02em',
      lineHeight: '72px',
    },
    'display-xl-semibold': {
      fontSize: '60px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: '72px',
    },
    'display-xl-bold': {
      fontSize: '60px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '72px',
    },
    'display-lg-regular': {
      fontSize: '48px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '-0.02em',
      lineHeight: '60px',
    },
    'display-lg-medium': {
      fontSize: '48px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '-0.02em',
      lineHeight: '60px',
    },
    'display-lg-semibold': {
      fontSize: '48px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: '60px',
    },
    'display-lg-bold': {
      fontSize: '48px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '60px',
    },
    'display-md-regular': {
      fontSize: '36px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '-0.02em',
      lineHeight: '44px',
    },
    'display-md-medium': {
      fontSize: '36px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '-0.02em',
      lineHeight: '44px',
    },
    'display-md-semibold': {
      fontSize: '36px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '-0.02em',
      lineHeight: '44px',
    },
    'display-md-bold': {
      fontSize: '36px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '-0.02em',
      lineHeight: '44px',
    },
    'display-sm-regular': {
      fontSize: '30px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '38px',
    },
    'display-sm-medium': {
      fontSize: '30px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '38px',
    },
    'display-sm-semibold': {
      fontSize: '30px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '38px',
    },
    'display-sm-bold': {
      fontSize: '30px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '38px',
    },
    'display-xs-regular': {
      fontSize: '24px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '32px',
    },
    'display-xs-medium': {
      fontSize: '24px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '32px',
    },
    'display-xs-semibold': {
      fontSize: '24px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '32px',
    },
    'display-xs-bold': {
      fontSize: '24px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '32px',
    },
    'text-xl-regular': {
      fontSize: '20px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '30px',
    },
    'text-xl-medium': {
      fontSize: '20px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '30px',
    },
    'text-xl-semibold': {
      fontSize: '20px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '30px',
    },
    'text-xl-bold': {
      fontSize: '20px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '30px',
    },
    'text-lg-regular': {
      fontSize: '18px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '28px',
    },
    'text-lg-medium': {
      fontSize: '18px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '28px',
    },
    'text-lg-semibold': {
      fontSize: '18px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '28px',
    },
    'text-lg-bold': {
      fontSize: '18px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '28px',
    },
    'text-md-regular': {
      fontSize: '16px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '24px',
    },
    'text-md-medium': {
      fontSize: '16px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '24px',
    },
    'text-md-semibold': {
      fontSize: '16px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '24px',
    },
    'text-md-bold': {
      fontSize: '16px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '24px',
    },
    'text-sm-regular': {
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '20px',
    },
    'text-sm-medium': {
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '20px',
    },
    'text-sm-semibold': {
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '20px',
    },
    'text-sm-bold': {
      fontSize: '14px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '20px',
    },
    'text-xs-regular': {
      fontSize: '12px',
      fontFamily: 'Manrope',
      fontWeight: 400,
      letterSpacing: '0em',
      lineHeight: '18px',
    },
    'text-xs-medium': {
      fontSize: '12px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      letterSpacing: '0em',
      lineHeight: '18px',
    },
    'text-xs-semibold': {
      fontSize: '12px',
      fontFamily: 'Manrope',
      fontWeight: 600,
      letterSpacing: '0em',
      lineHeight: '18px',
    },
    'text-xs-bold': {
      fontSize: '12px',
      fontFamily: 'Manrope',
      fontWeight: 700,
      letterSpacing: '0em',
      lineHeight: '18px',
    },
  },
});

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <BrowserRouter basename="/">
          <ScrollToTop />
          <React.Suspense
            fallback={
              <Flex justifyContent="center" alignItems="center" h="100vh" w="100%">
                <Spinner size="lg" />
              </Flex>
            }
          >
            {children}
          </React.Suspense>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
};
