import { Button, Box, Center, Flex, Heading, Image, Show as ChakraShow, Text } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';
import { environment } from '../../../../environments/environment';
import { BrandSpinner, ButtonIcon, WindowAlert } from '../../../components';
import { verifyEmail } from '../../../lib/apis/user';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const nonce = searchParams.get('nonce');

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function postVerifyEmail() {
      if (nonce) {
        try {
          const response = await verifyEmail(nonce);
          setSuccess(response.successful === true && response.payload === true);
        } catch (error) {
          setSuccess(false);
          setErrorMessage((error as Error)?.message);
        }
      }
      setIsLoading(false);
    }
    //
    postVerifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to verify your credentials"
        alertMessage={errorMessage}
        alertStatus="error"
        isOpen={errorMessage !== ''}
        onClose={() => setErrorMessage('')}
      />

      <Flex>
        <ChakraShow breakpoint="(min-width: 992px)">
          <Center w="550px" me={100}>
            <Image src={dynamicInvestSvg} w="550px" h="545px" />
          </Center>
        </ChakraShow>

        <Box w="500px" mt={{ base: '0rem', lg: '4rem' }}>
          <Heading textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
            {isLoading ? 'Verifying email' : success ? 'Email Verified' : 'There was a problem'}
          </Heading>

          <Text color="brand.gray.500" mt={2} mb={10} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
            {isLoading
              ? 'We are verifying your email; please hold tight.'
              : success
                ? 'Your email has been verified successfully. Click below to log in.'
                : `We were unable to verify your email. Please contact ${environment.SupportName}.`}
          </Text>

          {isLoading && (
            <Center w="365px">
              <BrandSpinner />
            </Center>
          )}

          {!isLoading && success && (
            <Button
              bgColor="brand.blue.700"
              color="white"
              h="48px"
              w={{ base: '100%', md: 'fit-content' }}
              pe="2px"
              rightIcon={<ButtonIcon />}
              textStyle="text-md-medium"
              type="button"
              onClick={() => navigate('/auth/login')}
            >
              Continue to Log In
            </Button>
          )}

          {!isLoading && !success && (
            <Button
              bgColor="brand.blue.700"
              color="white"
              h="48px"
              w={{ base: '100%', md: 'fit-content' }}
              pe="2px"
              rightIcon={<ButtonIcon />}
              textStyle="text-md-medium"
              type="button"
              onClick={() => navigate('/auth/register')}
            >
              Contact {environment.SupportName}
            </Button>
          )}
        </Box>
      </Flex>
    </>
  );
};
