import { Box, Center, Text, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import Persona from 'persona';
import PersonaReact from 'persona-react';
import React, { useRef, useState } from 'react';
import './VerifyIdentityForm.css';

import { environment } from '../../../../../environments/environment';
import { BaseFormProps, BrandSpinner } from '../../../../components';
import { initiateKycVerification, saveKycInquiry } from '../../../../lib/apis';
import { IBaseResponse } from '../../../../lib/interfaces';
import { UserKycInquiryRequest, UserKycResponse } from '../../../../lib/types';

Persona.Client.preload();

interface IFormInput {
  isKycComplete: boolean;
}

interface PersonaEnvironment {
  environment: 'sandbox' | 'production' | undefined;
  inquiryId: string | null | undefined;
  referenceId: string;
  templateId: string;
}

export const VerifyIdentityForm = ({ onSuccess }: BaseFormProps<IFormInput>) => {
  const defaultLoadErrorMessage = `We could not initiate the verification process. Please contact ${environment.SupportName}`;
  const defaultSaveErrorMessage = `Failed to verify you. Please contact  ${environment.SupportName}.`;

  // handling page state
  const [errorMessage, setErrorMessage] = useState('');
  const [kycComplete, setKycComplete] = useState(false);
  const [kycError, setKycError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // handling Persona state
  const [kycOptions, setKycOptions] = useState<PersonaEnvironment>({
    environment: undefined,
    inquiryId: null,
    referenceId: '',
    templateId: '',
  });

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadKycConfiguration() {
      try {
        const response = await initiateKycVerification();
        if (!response.successful || response.payload === null) {
          setErrorMessage(defaultLoadErrorMessage);
          setKycError(true);
        } else if (!response.payload.isKycComplete) {
          setKycOptions({
            ...kycOptions,
            environment: response.payload.kycEnvironment,
            inquiryId: response.payload.inquiryId,
            referenceId: response.payload.externalUUID,
            templateId: response.payload.templateId,
          });

          // if inquiry is pending, get a session token to continue
          // ref: https://docs.withpersona.com/reference/resume-an-inquiry
        } else {
          // Need to say congrats, mark step as complete & move on?!
          setKycComplete(true);
          onKycComplete();
        }
      } catch (error) {
        setErrorMessage((error as AxiosError<IBaseResponse<UserKycResponse>>)?.response?.data?.errorMessages?.toString() || defaultLoadErrorMessage);
        setKycError(true);
        console.log(`error! ${error}`);
      }

      setIsLoading(false);
    }

    loadKycConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User actually clicked the complete button!
  const onKycComplete = async () => {
    const formValues: IFormInput = {
      isKycComplete: true,
    };
    onSuccess(formValues);
  };

  // User initiated the KYC collection process
  const onKycInitiated = async (inquiryId: string) => {
    try {
      const request: UserKycInquiryRequest = {
        inquiryId: inquiryId,
      };
      const response = await saveKycInquiry(request);

      if (!response.successful || !response.payload) {
        setErrorMessage((response.errorMessages?.length || 0) < 1 ? defaultSaveErrorMessage : response.errorMessages.toString());
        setKycError(true);
      }
    } catch (error) {
      setErrorMessage((error as AxiosError<IBaseResponse<boolean>>)?.response?.data?.errorMessages?.toString() || defaultSaveErrorMessage);
      setKycError(true);
    }
  };

  if (isLoading || kycComplete)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '90vw', lg: '600px' }}>
          <Text color="gray.400" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }} mb={8}>
            We are downloading content. Please wait...
          </Text>

          <BrandSpinner />
        </VStack>
      </Center>
    );

  if (kycError)
    return (
      <Center>
        <VStack mt="5%" w={{ base: '90vw', lg: '600px' }}>
          <Text color="gray.400" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }}>
            {errorMessage}
          </Text>
        </VStack>
      </Center>
    );

  // EAGLE-7035 Reinitiate Pending Inquiry for KYC -> server side needs to provide a session token from Persona
  // if (kycOptions.inquiryId) {
  //   return (
  //     <Box h="100%" w={{ base: '90vw', lg: '100%' }}>
  //       <Center h="90vh">
  //         <div className="personaWidget">
  //           <PersonaReact
  //             // This refers to a production demo template owned by Persona
  //             environment={kycOptions.environment}
  //             referenceId={kycOptions.referenceId}
  //             inquiryId={kycOptions.inquiryId}
  //             onComplete={({ inquiryId, status, fields }) => {
  //               console.log('onComplete', inquiryId, status, fields);
  //               onKycComplete();
  //             }}
  //             onCancel={({ inquiryId, sessionToken }) => console.log('onCancel', inquiryId)}
  //             onError={(error) => console.log(error)}
  //             onEvent={(name, metaData) => {
  //               switch (name) {
  //                 case 'start': {
  //                   console.log(`Received event: start with inquiry ID ${metaData?.inquiryId}`);
  //                   const inquiryId = metaData?.inquiryId as string;
  //                   if (inquiryId) onKycInitiated(inquiryId);
  //                   break;
  //                 }
  //                 default:
  //                   console.log(`Received event: ${name} with meta: ${JSON.stringify(metaData)}`);
  //               }
  //             }}
  //           />
  //         </div>
  //       </Center>
  //     </Box>
  //   );
  // }

  return (
    <Box mt={{ base: '15%', lg: '0%' }} h={{ base: '90vh', lg: '100%' }} w={{ base: '90vw', lg: '100%' }}>
      <Center h="90vh">
        <div className="personaWidget">
          <PersonaReact
            // This refers to a production demo template owned by Persona
            templateId={kycOptions.templateId}
            environment={kycOptions.environment}
            referenceId={kycOptions.referenceId}
            onComplete={({ inquiryId, status, fields }) => {
              console.log('onComplete', inquiryId, status, fields);
              onKycComplete();
            }}
            onCancel={({ inquiryId, sessionToken }) => console.log('onCancel', inquiryId)}
            onError={(error) => console.log(error)}
            onEvent={(name, metaData) => {
              switch (name) {
                case 'start': {
                  console.log(`Received event: start with inquiry ID ${metaData?.inquiryId}`);
                  const inquiryId = metaData?.inquiryId as string;
                  if (inquiryId) onKycInitiated(inquiryId);
                  break;
                }
                default:
                  console.log(`Received event: ${name} with meta: ${JSON.stringify(metaData)}`);
              }
            }}
          />
        </div>
      </Center>
    </Box>
  );
};
