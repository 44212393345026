import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { UserExchangeDto, UserExchangeRequest } from '../types';

export const createUserExchange = async (data: UserExchangeRequest): Promise<IBaseResponse<UserExchangeDto>> => {
  return axios.post('/user-exchange', data);
};

export const getUserExchange = async (userStrategyId: string): Promise<IBaseResponse<UserExchangeDto>> => {
  return axios.get('/user-exchange', { params: { userStrategyId: userStrategyId } });
};

export const getUserExchangeIpWhitelist = async (): Promise<IBaseResponse<string>> => {
  return axios.get('/user-exchange/ip-whitelist');
};
