import { Box, Button, Flex, Image, Link as ChakraLink, Spacer, Text, VStack } from '@chakra-ui/react';
import { faLayerGroup, faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link as ReactRouterLink, useLocation, useNavigate } from 'react-router-dom';

import logo from '../../assets/dynamic-logo.svg';
import iconClockCircle from '../../assets/icon-clock-circle.svg';
import iconMail from '../../assets/icon-mail.svg';
import iconMessageQuestion from '../../assets/icon-message-question.svg';
import iconPhone from '../../assets/icon-phone.svg';
import { environment } from '../../environments/environment';

export const SideNav = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log('side nav: state passed via useLocation', state);

  const learningCenterLink = environment.LearningCenterUrl;
  const learningCenterName = environment.LearningCenterName;
  const supportEmail = environment.SupportEmail;
  const supportHours = environment.SupportHours;
  const supportName = environment.SupportName;
  const supportPhone = environment.SupportPhone;

  const emailSupport = () => {
    window.location.href = `mailto:${supportEmail}`;
  };

  return (
    <Box pl={11} py={10} borderRight="1px solid" borderRightColor="brand.borderLight" minW="265px">
      <Flex flexDirection="column" justifyContent="space-between" h="100%">
        <Box ml={8}>
          <Image src={logo} h="22px" w="106px" />

          <VStack alignItems="start" mt="42px">
            <Button
              onClick={() => navigate('/home')}
              color="blue.700" // black when not selected
              textStyle="text-sm-medium"
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              mb={6}
              leftIcon={<FontAwesomeIcon icon={faLayerGroup} fontSize="24px" />}
            >
              <Text textStyle="text-sm-medium" ms="8px">
                Home
              </Text>
            </Button>

            <Button
              onClick={() => navigate('/home')}
              color="black" // blue.700 when selected
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              leftIcon={<FontAwesomeIcon icon={faCog} fontSize="24px" />}
            >
              <Text textStyle="text-sm-medium" ms="8px">
                Settings
              </Text>
            </Button>
          </VStack>

          <Spacer />

          <VStack alignItems="start" position="absolute" bottom="40px">
            <ChakraLink
              as={ReactRouterLink}
              to={`tel:${supportPhone}`}
              color="gray.500"
              textStyle={'text-sm-medium'}
              textDecoration="none"
              padding={0}
              w="100%"
              justifyContent="start"
              mb={4}
            >
              <Button
                color="gray.500"
                textStyle="text-sm-medium"
                variant="ghost"
                padding={0}
                w="100%"
                justifyContent="start"
                leftIcon={<Image src={iconPhone} h="24px" w="24px" />}
              >
                <Text textStyle="text-sm-medium" ms="8px">
                  {supportPhone}
                </Text>
              </Button>
            </ChakraLink>

            <Button
              onClick={() => emailSupport()}
              color="gray.500"
              textStyle="text-sm-medium"
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              mb={4}
              leftIcon={<Image src={iconMail} h="24px" w="24px" />}
            >
              <ChakraLink as={ReactRouterLink} to={`mailto:${supportEmail}`} ms="8px" textStyle={'text-sm-medium'} textDecoration="none">
                <Text textStyle="text-sm-medium">{supportName}</Text>
              </ChakraLink>
            </Button>

            <Flex color="gray.500" padding={0} w="100%" justifyContent="start" mb={4}>
              <Image src={iconClockCircle} h="24px" w="24px" style={{ marginInlineEnd: '8px' }} />
              <Text textStyle="text-sm-medium" ms="8px">
                {supportHours}
              </Text>
            </Flex>

            <Button
              onClick={() => window.open(learningCenterLink, '_blank', 'rel=noopener noreferrer')}
              color="gray.500"
              textStyle="text-sm-medium"
              variant="ghost"
              padding={0}
              w="100%"
              justifyContent="start"
              leftIcon={<Image src={iconMessageQuestion} h="24px" w="24px" />}
            >
              <Text textStyle="text-sm-medium" ms="8px">
                {learningCenterName}
              </Text>
            </Button>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

SideNav.displayName = 'SideNav';
