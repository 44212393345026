import { CheckCircleIcon, WarningIcon as ChakraWarningIcon } from '@chakra-ui/icons';
import {
  Button as ChakraButton,
  Icon as ChakraIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  VStack,
  Flex,
  ModalFooter,
  ModalHeader,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import React from 'react';

type ConfirmationModalProps = React.HTMLAttributes<HTMLElement> & {
  isOpen: boolean;
  isSuccess?: boolean;
  onBackButtonClick?: () => void;
  onClose: () => void;
  onContinueButtonClick?: () => void;
  backButtonText?: string;
  continueButtonText?: string;
  headerText?: string;
  modalStyle?: any;
};

const CheckIcon = (props: any) => {
  return <CheckCircleIcon {...props} />;
};

const WarningIcon = (props: any) => {
  return <ChakraWarningIcon {...props} />;
};

// TODO CSS styles did not override override ModalContent styles?!
const defaultModalStyle = {
  boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
  // minW: 'fit-content',
  // minh: 'fit-content',
  // size: 'xl',
  // minH: '400px',
  // minW: '900px',
};

export const ConfirmationModal = ({
  isOpen,
  isSuccess = true,
  onBackButtonClick,
  onContinueButtonClick,
  onClose,
  backButtonText,
  continueButtonText,
  headerText,
  modalStyle = {},
  ...props
}: ConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent px={12} py={12} boxShadow="0px 4px 30px 0px rgba(0, 0, 0, 0.10)" minH="400px" minW={{ base: '80%', lg: '900px' }}>
        <ModalCloseButton />
        <ModalHeader justifyContent="center">
          <VStack justifyContent="center">
            {isSuccess && <ChakraIcon as={CheckIcon} mb="6px" w={8} h={8} color="brand.success.700" />}
            {!isSuccess && <ChakraIcon as={WarningIcon} mb="6px" w={8} h={8} color="brand.warning.700" />}
            {headerText && (
              <Text textStyle="display-xs-semibold" mb={2}>
                {headerText}
              </Text>
            )}
          </VStack>
        </ModalHeader>
        <ModalBody borderRadius="8px" sx={{ modalStyle, defaultModalStyle }}>
          <VStack justifyContent="center">
            {/* Display content below */}
            <Flex>{props.children}</Flex>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <HStack>
            {backButtonText && (
              <ChakraButton
                bgColor="white"
                border="1px"
                borderColor="blue.700"
                borderRadius="8px"
                marginTop={4}
                paddingX={4}
                paddingY="14px"
                type="button"
                onClick={onBackButtonClick ? onBackButtonClick : undefined}
              >
                <Text color="blue.700" textStyle="text-md-medium">
                  {backButtonText}
                </Text>
              </ChakraButton>
            )}

            {backButtonText && continueButtonText && <Spacer />}

            {continueButtonText && (
              <ChakraButton
                bgColor="blue.700"
                border="1px"
                borderColor="blue.700"
                borderRadius="8px"
                marginTop={4}
                paddingX={4}
                paddingY="14px"
                type="button"
                onClick={onContinueButtonClick ? onContinueButtonClick : undefined}
              >
                <Text color="white" textStyle="text-md-medium">
                  {continueButtonText}
                </Text>
              </ChakraButton>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
