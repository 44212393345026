export type UserExchangeRequest = {
  portfolioName: string; // max-length: 250
  apiKeyName: string; // max-length: 250
  privateKey: string; //max-length: 550
  userStrategyId: string;
};

export const ApiKeyNameMaxLength = 250;
export const PortfolioNameMaxLength = 250;
export const PrivateKeyMaxLength = 550;
