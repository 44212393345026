import React from 'react';

import { OnboardRoutes } from '../features';
import { MainRoutes } from '../features/main/routes';

export const protectedRoutes = [
  {
    path: '/onboard/*',
    element: <OnboardRoutes />,
  },
  {
    path: '/*',
    element: <MainRoutes />,
  },
];
