import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';

export type WindowAlertProps = React.HTMLAttributes<HTMLElement> & {
  alertTitle: string;
  alertMessage?: string;
  alertStatus: AlertStatus;
  isOpen: boolean;
  onClose: () => void;
};

const boxStyle = {
  boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
};

export const WindowAlert = ({ alertTitle, alertMessage, alertStatus, isOpen, onClose, ...props }: WindowAlertProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxH="80vh" maxW="80vw" w="fit-content" mx={0} my={4}>
        <ModalBody sx={{ boxStyle }} px={0} py={0}>
          <Alert
            status={alertStatus}
            variant="subtle"
            borderRadius="8px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            h="fit-content"
            px={10}
            py={10}
          >
            <AlertIcon boxSize="40px" mx={0} my={0} />
            <ModalCloseButton />
            <AlertTitle mt={4} mb={4} fontSize="lg">
              {alertTitle}
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              {alertMessage && <Text textStyle="">{alertMessage}</Text>}
              {props.children}
            </AlertDescription>
          </Alert>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

WindowAlert.displayName = 'WindowAlert';
