import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AlternateLayout } from '../../../components/Layout/AlternateLayout';
import { MainLayout } from '../../../components/Layout/MainLayout';

import { Home } from './Home';
import { PlatformAgreement } from './PlatformAgreement';

export const MainRoutes = () => {
  return (
    <Routes>
      {/* These paths use the alternate layout, no navigation or header */}
      <Route path="/msc/*" element={<AlternateLayout />}>
        <Route path="platformAgreement" element={<PlatformAgreement />} />
      </Route>
      {/* These paths have a header and navigation */}
      <Route path="/" element={<MainLayout />}>
        <Route path="/home" element={<Home />} />
      </Route>
    </Routes>
  );
};
