import { Flex, Image, Link as ChakraLink, Spacer, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import logo from '../../assets/dynamic-logo.svg';
import menuIcon from '../../assets/icon-menu.svg';
import menuCloseIcon from '../../assets/icon-x-close.svg';
import { environment } from '../../environments/environment';

export const TopNav = () => {
  const { state } = useLocation();
  const [display, changeDisplay] = useState('none');

  console.log('top nav: state passed via useLocation', state);

  const learningCenterLink = environment.LearningCenterUrl;
  const learningCenterName = environment.LearningCenterName;

  return (
    <>
      <Flex width="100vw" pt=".75rem" pb=".5rem" px="1rem" borderBottom="1px solid" borderBottomColor="brand.borderLight">
        <Flex alignItems="center">
          <Image src={logo} h="20px" w="96.25px" title="Dynamic Invest" />
        </Flex>

        <Spacer />

        <IconButton
          aria-label="Open Menu"
          size="md"
          mr={2}
          bgColor="white"
          icon={
            <Image
              src={display === 'none' ? menuIcon : menuCloseIcon}
              h={display === 'none' ? '36px' : '24px'}
              w={display === 'none' ? '36px' : '24px'}
            />
          }
          onClick={() => {
            display === 'flex' ? changeDisplay('none') : changeDisplay('flex');
          }}
        />
      </Flex>

      <Flex width="100vw" flexDirection="column" display={display} mt={8} mb={18} overflowY="auto" bgColor="white">
        <Flex flexDir="column" align="flex-start" ms={4} gap={4} overflowY="auto" bgColor="white" zIndex={20}>
          <ChakraLink
            as={ReactRouterLink}
            to={'/home'}
            textStyle={'text-md-medium'}
            paddingBottom={4}
            borderBottom="1px solid"
            borderBottomColor="brand.borderLight"
            width="90vw"
          >
            Home
          </ChakraLink>

          <Spacer></Spacer>

          <ChakraLink
            as={ReactRouterLink}
            to={'/home'}
            textStyle={'text-md-medium'}
            paddingBottom={4}
            borderBottom="1px solid"
            borderBottomColor="brand.borderLight"
            width="90vw"
          >
            Settings
          </ChakraLink>

          <Spacer></Spacer>

          <ChakraLink
            as={ReactRouterLink}
            to={learningCenterLink}
            textStyle={'text-md-medium'}
            paddingBottom={4}
            borderBottom="1px solid"
            borderBottomColor="brand.borderLight"
            width="90vw"
          >
            {learningCenterName}
          </ChakraLink>
        </Flex>
      </Flex>
    </>
  );
};

TopNav.displayName = 'TopNav';
