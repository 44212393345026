import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { StrategiesDto, StrategyDetailDto, StrategyDto } from '../types';

export const getStrategies = async (): Promise<IBaseResponse<StrategiesDto>> => {
  return axios.get('/strategies');
};

export const getStrategy = async (id: string): Promise<IBaseResponse<StrategyDto>> => {
  return axios.get(`/strategy/${id}`);
};

export const getStrategyDetail = async (id: string): Promise<IBaseResponse<StrategyDetailDto>> => {
  return axios.get(`/strategy/${id}/detail`);
};
