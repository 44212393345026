import { Box, HStack, Image as ChakraImage, Show, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export interface IConfirmationTextInterface {
  buttonText: string;
  continuationText: string;
  logoURL: string;
  strategyName: string;
}

// Text for confirmation modal
export const ConfirmationText: React.FC<IConfirmationTextInterface> = (props: IConfirmationTextInterface) => {
  return (
    <Box mt={5} mb={5}>
      <Show above="md">
        <HStack mb={2}>
          <Text color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
            You have selected{' '}
          </Text>
          <ChakraImage src={props.logoURL} w="24px" h="24px" ms={2}></ChakraImage>
          <Text as="span" color="brand.gray.500" textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
            {props.strategyName}
          </Text>{' '}
          <Text color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
            for your investment portfolio.
          </Text>
        </HStack>
      </Show>

      <Show below="lg">
        <VStack>
          <Text as="span" color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
            You have selected{' '}
          </Text>

          <HStack>
            <ChakraImage src={props.logoURL} w="24px" h="24px" ms={2}></ChakraImage>
            <Text as="span" color="brand.gray.500" textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
              {props.strategyName}
            </Text>{' '}
          </HStack>

          <Text color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
            for your investment portfolio.
          </Text>
        </VStack>
      </Show>

      <VStack mt={5}>
        <Text color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          Click{' '}
          <Text as="span" color="brand.gray.500" textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
            “{props.buttonText}”
          </Text>{' '}
          {props.continuationText}.
        </Text>

        <Text color="brand.gray.500" textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          Click{' '}
          <Text as="span" color="brand.gray.500" textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
            “Back”
          </Text>{' '}
          to review your strategy options.
        </Text>
      </VStack>
    </Box>
  );
};
