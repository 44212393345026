export type UserExchangeDto = {
  id: string;
  exchangeAccountName: string | null;
  exchangeAccountUUID: string | null;
  isActive: boolean;
};

export const EmptyUserExchange: UserExchangeDto = {
  id: '',
  exchangeAccountName: null,
  exchangeAccountUUID: null,
  isActive: false,
};
