import { sha256 } from 'crypto-hash';

import {
  login,
  registerUser,
  resetPassword,
  userState,
  SecureLoginCredentialsDto,
  SecureRegisterCredentialsDto,
  SecureResetPasswordCredentialsDto,
} from '../apis';
import { configureAuth } from '../hooks/configureAuth';
import { LoginCredentialsDto, LoginResponseDto, RegisterCredentialsDto, ResetPasswordCredentialsDto, UserInfoDto } from '../types';

async function userFn() {
  const userName = sessionStorage.getItem('userName') || '';
  console.log('Getting user, ', userName);

  // TODO: Session data lost? Need to reauthenticate?
  if (userName === null || userName === undefined || userName === '') return null;

  const response = await userState();
  if (response === null || !response.successful || response.payload === null || response.payload === undefined) return null;

  const userData: UserInfoDto = {
    email: userName,
    userName: userName,
    isCurrentAgreementAccepted: response.payload.isCurrentAgreementAccepted,
    isKYCVerified: response.payload.isKYCVerified,
    isStrategySubscribed: response.payload.isStrategySubscribed,
  };
  return userData;
}

async function handleLoginResponse(data: LoginResponseDto, user: string) {
  const { accessToken, refreshToken } = data;

  // Save token in browser storage
  sessionStorage.setItem('accessToken', accessToken);
  sessionStorage.setItem('refreshToken', refreshToken);

  // Save userName in browser storage
  sessionStorage.setItem('userName', user);

  return;
}

async function loginFn(data: LoginCredentialsDto) {
  const loginData: SecureLoginCredentialsDto = {
    EmailAddress: data.email,
    PasswordHash: await sha256(data.password),
  };
  const response = await login(loginData);
  if (response.successful === true && response.payload) await handleLoginResponse(response.payload, loginData.EmailAddress);
  return response.successful ? userFn() : null;
}

async function registerFn(data: RegisterCredentialsDto) {
  const passwordHash = await sha256(data.password);
  const registerData: SecureRegisterCredentialsDto = {
    EmailAddress: data.email,
    PasswordHash: passwordHash,
    PasswordHashRepeat: passwordHash,
    CaptchaToken: data.captchaToken,
  };
  const response = await registerUser(registerData);
  return response.successful ? data.email : null;
}

async function resetPasswordFn(data: ResetPasswordCredentialsDto) {
  const passwordHash = await sha256(data.password);
  const resetData: SecureResetPasswordCredentialsDto = {
    PasswordHash: passwordHash,
    PasswordHashRepeat: passwordHash,
    CaptchaToken: data.captchaToken,
    Nonce: data.nonce,
  };
  const response = await resetPassword(resetData);
  return response.successful;
}

async function logoutFn() {
  sessionStorage.removeItem('accessToken');
  sessionStorage.removeItem('userName');
  sessionStorage.removeItem('refreshToken');
  window.location.assign(window.location.origin as unknown as string);
}

export const { useUser, useLogin, useRegister, useResetPassword, useLogout, AuthLoader } = configureAuth({
  userFn,
  loginFn,
  registerFn,
  resetPasswordFn,
  logoutFn,
});
