import { Box, Center, Flex, Heading, Image, Show as ChakraShow, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';
import { UserInfoDto } from '../../../lib/types';
import { determineLandingPage } from '../../../lib/utils/user-events';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();

  const onSubmit = async (data: UserInfoDto | null) => {
    const targetUrl = determineLandingPage(data);
    navigate(`${targetUrl}`, { state: { userData: data } });
  };

  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src={dynamicInvestSvg} w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box w="500" mt={{ base: '0rem', lg: '4rem' }}>
        <Heading textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
          Log in
        </Heading>

        <Text color="brand.gray.500" my={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          Please enter email and password to access your account
        </Text>

        <LoginForm onSuccess={(data: UserInfoDto | null) => onSubmit(data)} />
      </Box>
    </Flex>
  );
};
