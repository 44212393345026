export const DigitRegex = new RegExp(/.*\d/);
export const LowercaseRegEx = new RegExp(/.*[a-z]/);
export const MinLengthRegEx = new RegExp(/.{8,}$/);
export const UppercaseRegex = new RegExp(/.*[A-Z]/);

// eslint-disable-next-line prettier/prettier
export const SpecialCharactersRegEx = new RegExp(
  /.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/
);

export const ValidPasswordRegex = new RegExp(
  `^(?=${[DigitRegex.source, LowercaseRegEx.source, UppercaseRegex.source, SpecialCharactersRegEx.source, MinLengthRegEx.source].join(')(?=')}).*$`
);
