export type OnboardingEventDto = {
  onboardingEventTypeId: number;
  name: string | null;
  description: string | null;
  sequence: number;
  uiComponent: string | null;
  isComplete: boolean;
  completedDate: Date | null;
};

export const DefaultOnboardingEventDto = {
  onboardingEventTypeId: 0,
  name: '',
  description: '',
  sequence: 0,
  uiComponent: '',
  isComplete: false,
  completedDate: null,
};
