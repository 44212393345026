export enum UIComponentType {
  billing = 'billing',
  connect_coinbase = 'connect coinbase',
  home = 'home',
  kyc = 'kyc',
  strategy = 'strategy selection',
}

export interface UIComponentSettingsType {
  targetUrl: string;
  continuationText: string;
}
