export type StrategyDetailDto = {
  id: string;
  name: string;
  description: string;
  logoUrl: string;
  factSheetUrl: string;
  monthlySubscriptionFee: number | null;
  inceptionDate: Date | null;
};

export type StrategyDto = {
  id: string;
  name: string | null;
  description: string | null;
  logoURL: string | null;
  // the following are loaded from the detail URL
  factSheetUrl: string | null;
  monthlySubscriptionFee: number | null;
  inceptionDate: Date | null;
};

export type StrategiesDto = {
  strategies: StrategyDto[] | null;
};

export const EmptyStrategy: StrategyDto = {
  id: '',
  name: '',
  description: '',
  logoURL: '',
  factSheetUrl: null,
  monthlySubscriptionFee: null,
  inceptionDate: null,
};

export const NoStrategies: StrategyDto[] = [];

export const EmptyStrategies: StrategiesDto = {
  strategies: [],
};
