import { Box, Flex, Show, VStack } from '@chakra-ui/react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { SideNav, TopNav } from '..';

export const OnboardLayout = () => {
  const { state } = useLocation();
  console.log('onboard layout: state passed via useLocation', state);

  return (
    <>
      {/* Navigation for laptop or larger display */}
      <Show above="md">
        <Flex h="100vh">
          <SideNav />

          <Box flex={1} pt={6} px={6}>
            {/* This is the content for the route target */}
            <Outlet />
          </Box>
        </Flex>
      </Show>

      {/* Navigation for tablet or smaller display */}

      <Show below="lg">
        <VStack w="100vw">
          <Box>
            <TopNav />
          </Box>

          <Box flex={1} pt={6} px={6}>
            {/* This is the content for the route target */}
            <Outlet />
          </Box>
        </VStack>
      </Show>
    </>
  );
};
