import React from 'react';

import { AuthRoutes } from '../features';

export const publicRoutes = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
];
