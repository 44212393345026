import { Box, Flex, Show, VStack } from '@chakra-ui/react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { Header, SideNav, TopNav } from '..';

export const MainLayout = () => {
  const { state } = useLocation();
  console.log('main layout: state passed via useLocation', state);

  return (
    <>
      <Show above="md">
        <Flex h="100vh">
          <SideNav />

          <Box flex={1} pt={6} px={6}>
            {/* Welcome header goes here then content should be vertically stacked in this box */}
            <Header />

            {/* This is the content for the route target */}
            <Outlet />
          </Box>
        </Flex>
      </Show>

      <Show below="lg">
        <VStack w="100vw">
          <TopNav />

          <Box flex={1} pt={2} px={6}>
            {/* Welcome header goes here then content should be vertically stacked in this box */}
            <Header />

            {/* This is the content for the route target */}
            <Outlet />
          </Box>
        </VStack>
      </Show>
    </>
  );
};
