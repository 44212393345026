// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  is_local: false,
  production: false,
  DynamicInvestUrl: 'https://api.dynamicinvest.dev',
  CftSiteKey: '0x4AAAAAAAfLNPRA3rZj4KPd',
  TargetEnvironment: 'development',
  // Dynamic Support
  SupportName: 'Dynamic Support',
  SupportEmail: 'support@dynamicinvest.com',
  SupportHours: '9AM - 6PM EST, M-F',
  SupportPhone: '999.999.9999',
  // Learning Center
  LearningCenterUrl: 'https://help.dynamicinvest.dev',
  LearningCenterName: 'Help Center',
  // Links to Content
  SecurityBestPracticesUrl: 'https://docs.cdp.coinbase.com/advanced-trade/docs/security#storing-credentials-securely',
  CoinbaseAdvancedApiSettingsUrl: 'https://coinbase.com/settings/api',
  CoinbaseAdvancedSignUpUrl: 'https://www.coinbase.com/signup?from=advanced',
  TransferAssetsUrl: 'https://www.google.com/',
};
