import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Button as ChakraButton,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { StrategyDto } from '../../../../lib/types';

type StrategyInformationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  strategy: StrategyDto;
};

const boxStyle = {
  boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
};

export const StrategyInformationModal = ({ isOpen, onClose, strategy }: StrategyInformationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxH="100vh" maxW="80vw" px={5} pt={8} pb={5} my={12}>
        <ModalCloseButton />
        <ModalBody borderRadius="8px" sx={{ boxStyle }} w="100%" h="100%">
          <Text textStyle={{ base: 'text-md-semibold', lg: 'display-xs-semibold' }} mb={2}>
            Strategy
          </Text>
          <Flex border="1px solid" borderColor="brand.borderLight" borderRadius={8} alignItems="stretch" px={4} py={5}>
            <VStack alignItems="stretch" w="100%">
              <HStack w="100%" mb={6}>
                <Image src={strategy.logoURL || ''} w="40px" h="40px" me={3}></Image>
                <VStack alignItems="start" gap={0}>
                  <Text color="black" mb={0} textStyle={{ base: 'text-md-bold', lg: 'display-md-bold' }}>
                    {strategy.name}
                  </Text>
                  <Text color="gray.500" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }} mt={0} ml={0}>
                    {strategy.description}
                  </Text>
                </VStack>
              </HStack>
              <Box w="100%">
                <Box w="100%" mb={6}>
                  <Text textStyle="text-sm-regular">
                    Monthly Subscription Fee:{' '}
                    {strategy.monthlySubscriptionFee === null
                      ? 'N/A'
                      : strategy.monthlySubscriptionFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                  </Text>
                  <Text textStyle="text-sm-regular">
                    Inception Date:{' '}
                    {strategy.inceptionDate === null
                      ? 'N/A'
                      : (strategy.inceptionDate instanceof Date ? strategy.inceptionDate : new Date(strategy.inceptionDate)).toLocaleDateString(
                          'en',
                          {
                            year: 'numeric',
                            day: '2-digit',
                            month: 'long',
                          }
                        )}
                  </Text>
                </Box>

                <ChakraButton
                  bgColor="white"
                  border="1px"
                  borderColor="blue.700"
                  borderRadius="8px"
                  paddingX={4}
                  paddingY="14px"
                  onClick={() => window.open(strategy.factSheetUrl ?? '', '_blank', 'rel=noopener noreferrer')}
                  type="button"
                >
                  <Text color="blue.700" textStyle="text-md-regular">
                    Fact Sheet
                  </Text>
                </ChakraButton>
              </Box>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
