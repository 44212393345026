import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { ForgotPasswordRequestDto } from '../types';

export type SecureRegisterCredentialsDto = {
  EmailAddress: string; // max-length: 200
  PasswordHash: string; // max-length: 64
  PasswordHashRepeat: string;
  CaptchaToken?: string;
};

export type SecureResetPasswordCredentialsDto = {
  Nonce: string;
  PasswordHash: string;
  PasswordHashRepeat: string;
  CaptchaToken?: string;
};

export type UserStateDto = {
  isCurrentAgreementAccepted: boolean;
  isKYCVerified: boolean;
  isStrategySubscribed: boolean;
  isSuitabilityVerified: boolean;
};

export const forgotPassword = async (data: ForgotPasswordRequestDto): Promise<IBaseResponse<boolean>> => {
  return await axios.put('/user/forgot-password', data);
};

export const registerUser = (data: SecureRegisterCredentialsDto): Promise<IBaseResponse<boolean>> => {
  return axios.post('/user', data);
};

export const resetPassword = async (data: SecureResetPasswordCredentialsDto): Promise<IBaseResponse<boolean>> => {
  return await axios.put('/user/change-password', data);
};

export const userState = async (): Promise<IBaseResponse<UserStateDto>> => {
  return axios.get('/user/state');
};

export const verifyEmail = (nonce: string): Promise<IBaseResponse<boolean>> => {
  return axios.get('/user/verify-email', { params: { type: 'email', nonce: nonce } });
};
