import { Button as ChakraButton, HStack, Text } from '@chakra-ui/react';
import React from 'react';

type FormButtonsProps = {
  displayBackButton?: boolean;
  displayNextButton?: boolean;
  isSubmitting: boolean;
  backButtonText?: string;
  nextButtonText?: string;
  goBack?: () => void;
  manualSubmit?: () => void;
};

export const FormButtons = ({
  displayBackButton = true,
  displayNextButton = true,
  isSubmitting,
  backButtonText = 'Back',
  nextButtonText = 'Continue',
  goBack,
  manualSubmit,
}: FormButtonsProps) => {
  return (
    <HStack w="100%" gap="16px">
      {displayBackButton && (
        <ChakraButton
          bgColor="white"
          border="1px"
          borderColor="blue.700"
          borderRadius="8px"
          isLoading={isSubmitting}
          paddingX={4}
          paddingY="14px"
          onClick={goBack ? goBack : undefined}
          type="button"
        >
          <Text color="blue.700" textStyle="text-md-regular">
            {backButtonText}
          </Text>
        </ChakraButton>
      )}

      {displayNextButton && (
        <ChakraButton
          bgColor="blue.700"
          border="1px"
          borderColor="blue.700"
          borderRadius="8px"
          isLoading={isSubmitting}
          paddingX={4}
          paddingY="14px"
          type={manualSubmit ? 'button' : 'submit'}
          onClick={manualSubmit ? manualSubmit : undefined}
        >
          <Text color="white" textStyle="text-md-regular">
            {nextButtonText}
          </Text>
        </ChakraButton>
      )}
    </HStack>
  );
};
