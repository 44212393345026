import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { LoginResponseDto } from '../types';
//import { environment } from '../../environments/environment';

export type SecureLoginCredentialsDto = {
  EmailAddress: string;
  PasswordHash: string;
};

export const login = async (data: SecureLoginCredentialsDto): Promise<IBaseResponse<LoginResponseDto>> => {
  return await axios.post('/auth', data);
};
