import { Box, Center, Image as ChakraImage, Link as ChakraLink, Text, VStack } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link as ReactRouterLink } from 'react-router-dom';

import contentImage from '../../../../../assets/screenshot-CompleteBillingInformation.png';
import { environment } from '../../../../../environments/environment';
import { BaseFormProps, BrandSpinner } from '../../../../components';

interface IFormInput {
  isBillingConfigured: boolean;
}

export const ConfigureBillingForm = ({ onSuccess }: BaseFormProps<IFormInput>) => {
  const {
    handleSubmit,
    //setValue,
    //formState: { isSubmitting },
  } = useForm<IFormInput>();

  // handling page state
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Get handle to form in order to "submit" the form
  const formRef = useRef<HTMLFormElement>(null);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function loadStrategyOptions() {
      try {
        // Placeholder
      } catch (error) {
        setHasError(true);
        window.alert(`error! ${error}`);
      }

      setIsLoading(false);
    }

    loadStrategyOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User selected strategy for investment
  const onSubmit = async (values: IFormInput) => {
    onSuccess(values);
  };

  if (isLoading)
    return (
      <Center>
        <VStack mt="5%" w="600px">
          <Text color="gray.400" textStyle="text-md-regular" mb={8}>
            We are downloading content. Please wait...
          </Text>

          <BrandSpinner />
        </VStack>
      </Center>
    );

  if (hasError)
    return (
      <Center>
        <VStack mt="5%" w="600px">
          <Text color="gray.400" textStyle="text-md-regular">
            We were unable to verify your identity. Please{' '}
            <ChakraLink as={ReactRouterLink} color="blue.700" textStyle="text-sm-semibold" to={''}>
              Contact {environment.SupportName}
            </ChakraLink>
            .
          </Text>
        </VStack>
      </Center>
    );

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Box w="100%" flexShrink={0}>
        <Center>
          <ChakraImage src={contentImage} w="952px" h="718px"></ChakraImage>
        </Center>
      </Box>
    </form>
  );
};
