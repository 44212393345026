import { Box, Button, Center, Flex, Heading, Image, Link as ChakraLink, Show as ChakraShow, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';
import { ButtonIcon } from '../../../components';

export const ConfirmPasswordChanged = () => {
  const navigate = useNavigate();
  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src={dynamicInvestSvg} w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box w="500px" mt={{ base: '0rem', lg: '4rem' }}>
        <Heading textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
          Credentials Updated!
        </Heading>

        <Text color="brand.gray.500" mt={5} textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
          Your credentials were successfully updated.
        </Text>

        <Text color="brand.gray.500" mb={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          Click
          <ChakraLink as={ReactRouterLink} ms="4px" to={'/auth/login'}>
            <Text as="span" color="brand.gray.500" textStyle={{ base: 'text-sm-semibold', lg: 'text-xl-semibold' }}>
              “Continue to Log In”
            </Text>
          </ChakraLink>{' '}
          to access your account.
        </Text>

        <Flex mt={8}>
          <Button
            bgColor="brand.blue.700"
            color="white"
            h="48px"
            pe="2px"
            w={{ base: '100%', lg: 'fit-content' }}
            rightIcon={<ButtonIcon />}
            textStyle="text-md-medium"
            type="button"
            onClick={() => navigate('/auth/login')}
          >
            Continue to Log In
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
