import React from 'react';
import { useRoutes } from 'react-router-dom';

import { AnonymousLayout } from '../components';
import { Login } from '../features/auth/routes/Login';
import { useUser } from '../lib/providers/auth';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const user = useUser();

  const commonRoutes = [
    {
      path: '/',
      element: (
        <AnonymousLayout>
          <Login />
        </AnonymousLayout>
      ),
    },
  ];

  const routes = user.data ? protectedRoutes : [];

  const element = useRoutes([...publicRoutes, ...routes, ...commonRoutes]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{element}</>;
};
