export type UserInfoDto = {
  email: string;
  userName: string;
  isCurrentAgreementAccepted: boolean;
  isKYCVerified: boolean;
  isStrategySubscribed: boolean;
};

export function isUserInfoDto(obj: any): obj is UserInfoDto {
  return 'email' in obj && 'userName' in obj && 'isCurrentAgreementAccepted' in obj && 'isKYCVerified' in obj && 'isStrategySubscribed' in obj;
}
