import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { SpotPosition, UserDashboardDto } from '../types';

export const getUserDashboard = async (): Promise<IBaseResponse<UserDashboardDto>> => {
  return axios.get('/user/dashboard');
};

//***************************************************************************************************/
//***************************** TODO: Remove AFTER Demo *********************************************/
const demoPositionSummary: SpotPosition[] = [
  {
    assetName: 'Bitcoin',
    asset: 'BTC',
    assetImgUrl: 'https://ebadevv2staticassets.blob.core.windows.net/currency-logos/icon-btc-colored.svg',
    totalBalanceFiat: 631169.72,
    totalBalanceCrypto: 9.40980488,
    availableToTradeFiat: 631169.72,
    availableToTradeCrypto: 9.40980488,
    allocation: 90.35751195 / 100,
    oneDayChange: 100.36,
    costBasis: { value: 130266.72, currency: 'USD' },
    isCash: false,
  },
  {
    assetName: 'Etherum',
    asset: 'ETH',
    assetImgUrl: 'https://ebadevv2staticassets.blob.core.windows.net/currency-logos/icon-eth-colored.svg',
    totalBalanceFiat: 67355.18,
    totalBalanceCrypto: 17.87022156,
    availableToTradeFiat: 67355.18,
    availableToTradeCrypto: 17.87022156,
    allocation: 9.64248805 / 100,
    oneDayChange: -5.44,
    costBasis: { value: 20851.74, currency: 'USD' },
    isCash: false,
  },
  {
    assetName: 'US Dollar',
    asset: 'USD',
    assetImgUrl: 'https://ebadevv2staticassets.blob.core.windows.net/currency-logos/icon-usd-colored.svg',
    totalBalanceFiat: 0.0,
    totalBalanceCrypto: 0.0,
    availableToTradeFiat: 0.0,
    availableToTradeCrypto: 0.0,
    allocation: 0.0,
    oneDayChange: 0.0,
    costBasis: { value: 0.0, currency: 'USD' },
    isCash: true,
  },
];

export const getDashboardDataForDemo = async (): Promise<UserDashboardDto> => {
  const dashboardData: UserDashboardDto = {
    userStrategies: [
      {
        id: 'Portfolio with Call to Action',
        strategyId: '04C18F42-DE20-45E9-AB55-FA0A624687FB'.toLowerCase(),
        strategyName: 'Ark Bitcoin Alpha',
        strategyDescription: 'A strategy focused on alpha generation',
        completedOnboardingDate: new Date('2024-07-29T13:29:15.524486Z'),
        isBillingActive: true, // Call to action
        firstFundedDate: null, // Call to action
        exchangeAccountBalance: {
          exchangeAccountUUID: '',
          totalBalance: null,
          totalCashEquivalentBalance: null,
          totalCryptoBalance: null,
          spotPositions: null,
        },
        userStrategyOnboardingStatus: {
          onboardingEvents: [
            {
              onboardingEventTypeId: 1,
              name: 'Strategy Selection',
              description: 'Select Strategy to subscribe to',
              sequence: 1,
              uiComponent: 'Strategy Selection',
              isComplete: true,
              completedDate: new Date('2024-07-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 2,
              name: 'KYC',
              description: 'Identity Verificaiton',
              sequence: 1,
              uiComponent: 'KYC',
              isComplete: true,
              completedDate: new Date('2024-07-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 3,
              name: 'Connect Coinbase',
              description: 'Connection strategy to Coinbase portfolio',
              sequence: 3,
              uiComponent: 'Connect Coinbase',
              isComplete: true,
              completedDate: new Date('2024-07-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 4,
              name: 'Billing',
              description: 'Subscription Billing',
              sequence: 4,
              uiComponent: 'Billing',
              isComplete: true,
              completedDate: new Date('2024-07-29T13:29:15.524486Z'),
            },
          ],
        },
      },
      {
        id: 'Portfolio with Next Up',
        strategyId: 'CA6AF5B9-0F16-4220-B6B5-EEADA05786A1'.toLowerCase(),
        strategyName: 'Ark Altcoin Exposure',
        strategyDescription: 'A strategy focused on long only exposure to altcoins',
        completedOnboardingDate: null, // Next Up
        isBillingActive: false, // Call to action
        firstFundedDate: null, // Call to action
        exchangeAccountBalance: {
          exchangeAccountUUID: '',
          totalBalance: null,
          totalCashEquivalentBalance: null,
          totalCryptoBalance: null,
          spotPositions: null,
        },
        userStrategyOnboardingStatus: {
          onboardingEvents: [
            {
              onboardingEventTypeId: 1,
              name: 'Strategy Selection',
              description: 'Select Strategy to subscribe to',
              sequence: 1,
              uiComponent: 'Strategy Selection',
              isComplete: true,
              completedDate: new Date('2024-07-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 2,
              name: 'KYC',
              description: 'Identity Verificaiton',
              sequence: 1,
              uiComponent: 'KYC',
              isComplete: true,
              completedDate: new Date('2024-07-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 3,
              name: 'Connect Coinbase',
              description: 'Connection strategy to Coinbase portfolio',
              sequence: 3,
              uiComponent: 'Connect Coinbase',
              isComplete: false,
              completedDate: null,
            },
            {
              onboardingEventTypeId: 4,
              name: 'Billing',
              description: 'Subscription Billing',
              sequence: 4,
              uiComponent: 'Billing',
              isComplete: false,
              completedDate: null,
            },
          ],
        },
      },
      {
        id: 'Portfolio Complete w/Positions',
        strategyId: '04C18F42-DE20-45E9-AB55-FA0A624687FB'.toLowerCase(),
        strategyName: 'Ark Bitcoin Alpha',
        strategyDescription: 'A strategy focused on alpha generation',
        completedOnboardingDate: new Date('2024-06-29T13:29:15.524486Z'),
        isBillingActive: true,
        firstFundedDate: new Date('2024-06-29T13:29:15.524486Z'),
        exchangeAccountBalance: {
          exchangeAccountUUID: '0D5E5145-DA07-4582-A230-A32F25A1E15A',
          totalBalance: { value: 698524.9, currency: 'USD' },
          totalCashEquivalentBalance: { value: 698524.9, currency: 'USD' },
          totalCryptoBalance: { value: 698524.9, currency: 'USD' },
          spotPositions: demoPositionSummary,
        },
        userStrategyOnboardingStatus: {
          onboardingEvents: [
            {
              onboardingEventTypeId: 1,
              name: 'Strategy Selection',
              description: 'Select Strategy to subscribe to',
              sequence: 1,
              uiComponent: 'Strategy Selection',
              isComplete: true,
              completedDate: new Date('2024-06-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 2,
              name: 'KYC',
              description: 'Identity Verificaiton',
              sequence: 1,
              uiComponent: 'KYC',
              isComplete: true,
              completedDate: new Date('2024-06-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 3,
              name: 'Connect Coinbase',
              description: 'Connection strategy to Coinbase portfolio',
              sequence: 3,
              uiComponent: 'Connect Coinbase',
              isComplete: true,
              completedDate: new Date('2024-06-29T13:29:15.524486Z'),
            },
            {
              onboardingEventTypeId: 4,
              name: 'Billing',
              description: 'Subscription Billing',
              sequence: 4,
              uiComponent: 'Billing',
              isComplete: true,
              completedDate: new Date('2024-06-29T13:29:15.524486Z'),
            },
          ],
        },
      },
    ],
  };
  return dashboardData;
};
