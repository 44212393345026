import { VStack } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { CallToAction, CallToActionDto, NextUp, NextUpDto, NextUpStepType } from '../../../components';
import { getStrategies, getUserDashboard } from '../../../lib/apis';
//import { getDashboardDataForDemo } from '../../../lib/apis/userDashboard'; //uncomment for demo
import { useUser } from '../../../lib/providers/auth';
import { EmptyDashboardData, EmptyStrategies, UserStrategyDashboardDto } from '../../../lib/types';
import { getNextStep } from '../../../lib/utils/user-events';
import { useOnboardStore } from '../../onboard/stores/onboard';
import { HowToTransferFundsModal } from '../components/Modal/HowToTransferFundsModal';
import { Portfolio } from '../components/Portfolio';

export const Home = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log('home: state passed via useLocation', state);

  const { data: userData, isSuccess: userIsSuccess, isLoading: userIsLoading, error: userError } = useUser();

  const [componentIsLoading, setComponentIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(EmptyDashboardData);
  const [hasDashboardError, setHasDashboardError] = useState(false);
  const [strategyData, setStrategyData] = useState(EmptyStrategies);

  // handle Call  Modal state
  const [isOpen, setIsOpen] = useState(false);

  // Onboarding state
  const setOnboard = useOnboardStore((state) => state.setOnboard);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  React.useEffect(() => {
    if (isMountedRef.current === true || userError || !userIsSuccess) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    async function getDashboardData() {
      try {
        const response = await getUserDashboard();
        setHasDashboardError(!response.successful || response.payload === null || response.payload === undefined);
        if (!hasDashboardError) setDashboardData(response.payload);
      } catch (error) {
        setHasDashboardError(true);
      }

      // Uncomment for DEMO only
      // setDashboardData(await getDashboardDataForDemo());
      // setHasDashboardError(false);

      // TODO: Need to cache this
      try {
        const response = await getStrategies();
        setHasDashboardError(!response.successful || response.payload === null || response.payload === undefined);
        if (!hasDashboardError) setStrategyData(response.payload);
      } catch (error) {
        setHasDashboardError(true);
        console.log('error', error);
      }

      setComponentIsLoading(false);
    }
    //
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set-up Onboarding state for NextUp when clicked
  function nextUpOnClick(portfolio: UserStrategyDashboardDto) {
    const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
    const nextStepData = getNextStep(portfolio.userStrategyOnboardingStatus.onboardingEvents);
    setOnboard({
      strategy: {
        id: portfolio.strategyId,
        name: portfolio.strategyName,
        description: portfolio.strategyDescription,
        logoURL: strategy?.logoURL || '',
        factSheetUrl: null,
        monthlySubscriptionFee: null,
        inceptionDate: null,
      },
      onboardEvents: portfolio.userStrategyOnboardingStatus.onboardingEvents,
      userStrategyId: portfolio.id,
    });
    navigate(`${nextStepData.targetUrl}`);
  }

  //*******************************/
  //**** Functions to format data */
  //*******************************/
  // Call to Action (e.g. Fund Account)
  function formatCallToAction(portfolios: UserStrategyDashboardDto[]) {
    // Apply the style to a div that renders your text
    return portfolios
      .filter((p) => p.id !== '' && p.completedOnboardingDate !== null && p.firstFundedDate === null)
      .map((portfolio) => {
        const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
        const callToActionData: CallToActionDto = {
          strategyLogo: strategy?.logoURL,
          strategyName: strategy?.name,
          title: 'Add funds to your Coinbase portfolio',
          // eslint-disable-next-line quotes
          message: `We'll walk you through each step of the process.`,
          buttonText: 'How to Transfer Funds',
        };
        return <CallToAction key={portfolio.id} data={callToActionData} displayButton={true} onClick={() => setIsOpen(true)}></CallToAction>;
      });
  }

  // Next Up (e.g., Connect to Coinbase)
  function FormatNextUp(portfolios: UserStrategyDashboardDto[]) {
    // Apply the style to a div that renders your text
    return portfolios
      .filter((p) => p.id !== '' && p.completedOnboardingDate === null)
      .map((portfolio) => {
        const strategy = strategyData.strategies?.find((s) => s.id === portfolio.strategyId) ?? null;
        const nextStepData = getNextStep(portfolio.userStrategyOnboardingStatus.onboardingEvents);
        const nextStepsData: NextUpStepType[] = portfolio.userStrategyOnboardingStatus.onboardingEvents.map((step) => {
          const stepData: NextUpStepType = {
            name: step.name || '',
            isCurrent: step.name === nextStepData.name,
            isComplete: step.isComplete,
          };
          return stepData;
        });
        const nextUpData: NextUpDto = {
          strategyLogoUrl: strategy?.logoURL || '',
          strategyName: strategy?.name || '',
          stepTitle: nextStepData.name || '',
          stepMessage: nextStepData.description || '',
          stepUrl: nextStepData.targetUrl,
          // eslint-disable-next-line quotes
          buttonText: `Let's Go!`,
          nextSteps: nextStepsData,
        };
        return <NextUp key={portfolio.id} data={nextUpData} displaySteps={true} onClick={() => nextUpOnClick(portfolio)}></NextUp>;
      });
  }

  function FormatPortfolio(portfolios: UserStrategyDashboardDto[]) {
    return portfolios
      .filter((p) => p.id !== '' && p.completedOnboardingDate !== null && p.firstFundedDate !== null && p.isBillingActive === true)
      .map((portfolio) => {
        return <Portfolio key={portfolio.id} userStrategy={portfolio}></Portfolio>;
      });
  }

  //***********************************************************************/
  //******************** Display logic ************************************/
  if (userError || hasDashboardError) return <div>Request Failed</div>;
  if (userIsLoading || componentIsLoading) return <div>Loading...</div>;

  if (userIsSuccess) {
    if (!userData?.isCurrentAgreementAccepted) navigate('/msc/platformAgreement');

    return (
      <>
        <HowToTransferFundsModal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Transfer Assets into your Coinbase Portfolio" />

        <VStack gap={4} alignItems="start">
          {/* Call to Actions */}
          {dashboardData.userStrategies && formatCallToAction(dashboardData.userStrategies)}

          {/* Next Up */}
          {dashboardData.userStrategies && FormatNextUp(dashboardData.userStrategies)}

          {/* Portfolio Summary */}
          {dashboardData.userStrategies && FormatPortfolio(dashboardData.userStrategies)}
        </VStack>
      </>
    );
  }

  return <div>Unable to load dashboard. Please contact {environment.SupportName}.</div>;
};
