import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BrandSpinner } from '../../../components';
import { UIComponentType } from '../../../lib/interfaces';
import { getSequentialOnboardingStep } from '../../../lib/utils/user-events';
import { VerifyIdentityForm } from '../components/Form/VerifyIdentityForm';
import { ConfirmationModal } from '../components/Modal/ConfirmationModal';
import { getUrlForNextOnboardEvent, useOnboardStore } from '../stores/onboard';

interface IConfirmationTextInterface {
  verifiedIdentity: boolean;
  buttonText: string;
  continuationText: string;
}

// Text for confirmation state
const ConfirmationText: React.FC<IConfirmationTextInterface> = (props: IConfirmationTextInterface) => {
  if (!props.verifiedIdentity)
    return (
      <Box mt={5} mb={5}>
        <Text color="brand.gray.500" mb={2} textStyle="text-xl-regular">
          We cannot identify you!
        </Text>

        <Text color="brand.gray.500" textStyle="text-xl-regular">
          Click{' '}
          <Text as="span" color="brand.gray.500" textStyle="text-xl-semibold">
            “Try again”
          </Text>{' '}
          to try again.
        </Text>
      </Box>
    );

  return (
    <Box mt={5} mb={5}>
      <Text color="brand.gray.500" mb={2} textStyle="text-xl-regular">
        Thanks for verifying your identity.
      </Text>

      <Text color="brand.gray.500" textStyle="text-xl-regular">
        Click{' '}
        <Text as="span" color="brand.gray.500" textStyle="text-xl-semibold">
          “{props.buttonText}”
        </Text>{' '}
        {props.continuationText}
      </Text>
    </Box>
  );
};

export const VerifyIdentity = () => {
  const { state } = useLocation();
  console.log('verify identity: state passed via useLocation', state);
  const navigate = useNavigate();

  const [continueButtonText, setContinueButtonText] = useState('Continue');
  const [continueText, setContinueText] = useState('to continue');
  const [confirmed, setConfirmed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const onboardState = useOnboardStore((state) => state.onboard);
  console.log('verify identity: onboard state', onboardState);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    console.log('verify identity useEffect: onboard state', onboardState);

    // Determine confirmation text based on next steps
    if (onboardState.onboardEvents !== null) {
      const nextStep = getSequentialOnboardingStep(UIComponentType.kyc, onboardState.onboardEvents);
      if (nextStep.name !== null) setContinueButtonText(nextStep.name);
      setContinueText(nextStep.continuationText);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // For now, ass-u-me that the KYC collection process is complete and the user advances
  const onConfirmed = async () => {
    setConfirmed(true);

    const onboardEvent = onboardState.onboardEvents?.find((c) => c.name?.toLowerCase() === UIComponentType.kyc);
    if (onboardEvent !== null && onboardEvent !== undefined) {
      onboardEvent.completedDate = new Date();
      onboardEvent.isComplete = true;
    }

    navigate(`${await getUrlForNextOnboardEvent(onboardState)}`);
  };

  return (
    <Flex flexDirection="column" justifyContent="start" w="100%">
      <Box w="100%">
        {confirmed && (
          <Center>
            <VStack mt="5%" w="600px">
              <Text color="gray.400" textStyle="text-md-regular" mb={8}>
                We are updating your progress. Please wait...
              </Text>

              <BrandSpinner />
            </VStack>
          </Center>
        )}

        {!confirmed && (
          <>
            <ConfirmationModal
              isOpen={submitted && !confirmed}
              onClose={async () => onConfirmed()}
              onContinueButtonClick={async () => onConfirmed()}
              headerText="Congratulations, you're done!"
              continueButtonText={continueButtonText}
            >
              <VStack alignContent="stretch">
                <ConfirmationText verifiedIdentity={true} buttonText={continueButtonText} continuationText={continueText} />
              </VStack>
            </ConfirmationModal>

            <VerifyIdentityForm onSuccess={() => setSubmitted(true)} />
          </>
        )}
      </Box>
    </Flex>
  );
};
