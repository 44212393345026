import { Spinner as ChakraSpinner } from '@chakra-ui/react';
import React from 'react';

const sizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export type SpinnerProps = {
  size?: keyof typeof sizes;
  className?: string;
};

export const Spinner = ({ size = 'md', className = '' }: SpinnerProps) => {
  return <ChakraSpinner size={size} className={className} />;
};

export const BrandSpinner = () => {
  return <ChakraSpinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />;
};
