import { Button as ChakraButton } from '@chakra-ui/react';
import * as React from 'react';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
};

export const Button = ({ isLoading = false, ...props }: ButtonProps) => {
  return (
    <ChakraButton colorScheme="brand.blue" isLoading={isLoading} onClick={props.onClick}>
      {props.children}
    </ChakraButton>
  );
};

Button.displayName = 'Button';
