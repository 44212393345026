import { Box, Center, Flex, Heading, Image, Show as ChakraShow, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import dynamicInvestSvg from '../../../../assets/dynamic-invest.svg';
import { ResetPasswordForm } from '../components/ResetPasswordForm';

export const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <Flex>
      <ChakraShow breakpoint="(min-width: 992px)">
        <Center w="550px" me={100}>
          <Image src={dynamicInvestSvg} w="550px" h="545px" />
        </Center>
      </ChakraShow>

      <Box w="500px" mt={{ base: '0rem', lg: '4rem' }}>
        <Heading textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
          Change Password
        </Heading>

        <Text color="brand.gray.500" my={5} textStyle={{ base: 'text-sm-regular', lg: 'text-xl-regular' }}>
          Enter your new password.
        </Text>

        <ResetPasswordForm onSuccess={() => navigate('/auth/confirmPasswordChanged')} />
      </Box>
    </Flex>
  );
};
