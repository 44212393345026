import { Circle, Show } from '@chakra-ui/react';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const ButtonIcon = () => {
  return (
    <Show above="md">
      <Circle bg="white" borderRadius="20px" size="38px" ms="8px">
        <FontAwesomeIcon icon={faArrowRight} color="black" />
      </Circle>
    </Show>
  );
};
