import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { UserKycInquiryRequest, UserKycResponse } from '../types';

export const saveKycInquiry = async (data: UserKycInquiryRequest): Promise<IBaseResponse<boolean>> => {
  return axios.put('/user-kyc', data);
};

export const initiateKycVerification = async (): Promise<IBaseResponse<UserKycResponse>> => {
  return axios.get('/user-kyc');
};
