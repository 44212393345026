import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const Header = () => {
  const { state } = useLocation();

  return (
    <Flex flexDirection="row" mb={8}>
      <Text color="brand.black" textStyle={{ base: 'text-xl-regular', lg: 'display-sm-semibold' }}>
        Welcome back{state?.userName ? `,${state.userName}` : ''}
      </Text>
    </Flex>
  );
};

Header.displayName = 'Header';
